import { autocomplete } from './autocomplete';
import { avatar } from './avatar';
import { datepicker } from './date-picker';
import { chip } from './chip';
import { datatable } from './data-table';
import { floatlabel } from './float-label';
import { global } from './global';
import { inputgroup } from './input-group';
import { inputgroupaddon } from './input-group-addon';
import { inputmask } from './inputMask';
import { menu } from './menu';
import { multiselect } from './multi-select';
import { popover } from './popover';
import { select } from './select';
import { skeleton } from './skeleton';
import { paginator } from './paginator';

export const PrimeVuePt = {
  autocomplete,
  avatar,
  chip,
  datatable,
  datepicker,
  floatlabel,
  global,
  inputgroup,
  inputgroupaddon,
  inputmask,
  menu,
  multiselect,
  popover,
  select,
  skeleton,
  paginator
};
