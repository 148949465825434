<script setup lang="ts">
import { FontAwesomeIconProps } from '@fortawesome/vue-fontawesome';
import { useErrorHandler } from '@errors';
import { SUREbusinessManagementSearchSearchType as ResultType, useSearchGet } from '@services/overig/orval-api';
import { VContentBox, VInfoBox, VModal, VSpinnerV2, VStatusTag, type VStatusTagProps } from '@shared/component';
import { VSearch } from '@shared/form';
import { FontAwesomeIcon } from '@shared/plugin';
import { useMagicKeys, whenever } from '@vueuse/core';
import { computed, ref } from 'vue';

const active = ref<boolean>(false);
const focus = ref<boolean>(false);
const term = ref<string>();
const params = computed(() => ({ term: term.value }));

defineExpose({
  open: () => open()
});

const { data, isLoading, error } = useSearchGet(params, {
  query: {
    select: e => e.result,
    enabled: computed(() => !!term.value && term.value.length >= 3)
  }
});
useErrorHandler(error);

function getStatus(input: string): VStatusTagProps['color'] {
  switch (input) {
    case 'Actief':
    case 'Afgehandeld':
    case 'Akkoord':
    case 'Lopend':
      return 'teal';
    case 'Prospect':
      return 'grey';
    case 'In behandeling':
    case 'Afw. reactie TP':
    case 'Afw. reactie maatsch.':
      return 'orange';
    case 'Beëindigd':
    case 'Geroyeerd':
    case 'Geschorst':
      return 'red';
    default:
      return 'blue';
  }
}

function getIcon(input: ResultType): FontAwesomeIconProps['icon'] {
  switch (input) {
    case 'Relatie':
      return 'far fa-user';
    case 'Schade':
    case 'Schademelding':
      return 'far fa-car-crash';
    case 'Offerte':
      return 'far fa-calculator';
    case 'Aanvraag':
      return 'far fa-file-signature';
    case 'Mutatie':
      return 'far fa-file-circle-check';
    case 'Maatwerk':
      return 'far fa-memo-circle-check';
    case 'Royement':
      return 'far fa-file-xmark';
    case 'Polis':
      return 'far fa-file-contract';
    default:
      return 'far fa-file';
  }
}

const keys = useMagicKeys();

function close() {
  active.value = false;
  focus.value = false;
}

function open() {
  active.value = true;
  focus.value = true;
}

whenever(keys.ctrl_space, () => open());
</script>

<template>
  <VModal @close-modal="close()" confirm-button-text="" cancel-button-text="" :is-active="active">
    <div class="tw-flex tw-gap-2">
      <VSearch
        placeholder="Typ minstens 3 karakters om te zoeken"
        class="tw-grow"
        @input="e => (term = e)"
        :on-focus="focus" />
      <VSpinnerV2 class="tw-ml-2" v-if="isLoading" />
    </div>
    <div class="tw-max-h-[80svh]">
      <div class="tw-pb-2">
        <TransitionGroup name="list-complete">
          <VContentBox
            v-for="result in data"
            :key="result.omschrijving"
            :margin-bottom="0"
            no-padding
            class="tw-mt-2 tw-p-2 hover:tw-bg-grey-100">
            <a
              :href="result.url"
              class="tw-flex tw-flex-row tw-items-center tw-justify-between tw-gap-2 tw-text-grey-700">
              <div class="tw-flex tw-items-center tw-gap-4">
                <div class="tw-grid tw-size-12 tw-min-w-12 tw-place-items-center tw-rounded-full tw-bg-primary-200">
                  <FontAwesomeIcon size="lg" :icon="getIcon(result.type)" class="tw-text-primary" />
                </div>
                <span :title="result.omschrijving" class="tw-line-clamp-1 tw-break-all">
                  {{ result.omschrijving }}
                </span>
              </div>
              <div class="tw-flex tw-items-center">
                <VStatusTag inverted class="tw-text-nowrap tw-bg-white tw-text-xs" :color="getStatus(result.status)">
                  {{ result.status }}
                </VStatusTag>
                <div class="tw-px-4 tw-text-grey-700">
                  <FontAwesomeIcon size="lg" icon="far fa-chevron-right" />
                </div>
              </div>
            </a>
          </VContentBox>
        </TransitionGroup>
      </div>
    </div>
    <VInfoBox v-if="!term || term.length < 3">
      Zoek op relatienaam, relatienummer, polisnummer, schadenummer, offertenummer, KVK-nummer of kenteken.
    </VInfoBox>
    <VInfoBox v-if="data?.length === 0" type="warning">
      Er zijn geen resultaten gevonden. Probeer een andere zoekterm.
    </VInfoBox>
  </VModal>
</template>
