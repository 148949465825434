export const datepicker = {
  root: {
    class: ['tw-max-w-full', 'tw-absolute', 'tw-z-[1000]']
  },
  dropdownButton: {
    root: {
      class: [
        'tw-relative',
        'tw-items-center tw-inline-flex tw-text-center tw-align-bottom tw-justify-center',
        'tw-rounded-r-md',
        'tw-py-2 tw-px-0',
        'tw-w-10',
        'tw-leading-[normal]',
        'tw-text-primary-inverse',
        'tw-bg-primary',
        'tw-border tw-border-primary',
        'focus:tw-outline-none focus:tw-outline-offset-0 focus:tw-ring-1',
        'hover:tw-bg-primary-hover hover:tw-border-primary-hover',
        'focus:tw-ring-primary-500'
      ]
    }
  },
  inputicon: {
    class: ['tw-absolute tw-top-[50%] tw--mt-2', 'tw-text-grey-700', 'tw-right-[.75rem]', 'tw-z-10']
  },
  panel: ({ props }: Record<string, any>) => ({
    class: [
      // Display & Position
      {
        absolute: !props.inline,
        'tw-inline-block': props.inline
      },
      // Size
      { 'tw-w-auto tw-p-3 ': !props.inline },
      { 'tw-min-w-[80vw] tw-w-auto tw-p-3 ': props.touchUI },
      { 'tw-p-3 tw-min-w-full z-20': props.inline },
      // Shape
      'tw-border tw-rounded-lg',
      {
        'tw-shadow-md': !props.inline
      },
      // Colors
      'tw-bg-white',
      'tw-border-grey-200',
      // misc
      { 'tw-overflow-x-auto': props.inline }
    ]
  }),
  header: {
    class: [
      'tw-font-medium',
      'tw-flex tw-items-center tw-justify-between',
      'tw-p-0 tw-pb-2',
      'tw-m-0',
      'tw-border-b',
      'tw-rounded-t-md',
      'tw-text-surface-700',
      'tw-bg-white',
      'tw-border-grey-200'
    ]
  },
  pcPrevButton: {
    root: {
      class: [
        'tw-relative',
        'tw-inline-flex tw-items-center tw-justify-center',
        'tw-w-7 tw-h-7',
        'tw-p-0 tw-m-0',
        'tw-rounded-full',
        'tw-text-grey-500',
        'tw-border-0',
        'tw-bg-transparent',
        'tw-transition-colors tw-duration-200 tw-ease-in-out',
        'hover:tw-text-grey-800',
        'hover:tw-bg-grey-100',
        'focus:tw-outline-none focus:tw-outline-offset-0 focus:tw-ring-1 focus:tw-ring-primary-500 focus:tw-z-10',
        'tw-cursor-pointer tw-overflow-hidden'
      ]
    },
    label: {
      class: ['tw-hidden']
    }
  },
  title: {
    class: ['tw-leading-7', 'tw-mx-auto tw-my-0']
  },
  selectMonth: {
    class: [
      'tw-text-sm tw-leading-[normal] tw-capitalize',
      'tw-font-medium',
      'tw-rounded-md',
      'tw-text-grey-700',
      'tw-transition tw-duration-200',
      'tw-p-1',
      'tw-m-0 tw-mr-2',
      'hover:tw-text-primary-500',
      'focus:tw-outline-none focus:tw-outline-offset-0 focus:tw-ring-1 focus:tw-ring-primary-500 focus:tw-z-10',
      'tw-cursor-pointer'
    ]
  },
  selectYear: {
    class: [
      'tw-text-sm tw-leading-[normal]',
      'tw-font-medium',
      'tw-rounded-md',
      'tw-text-surface-700',
      'tw-transition tw-duration-200',
      'tw-p-1',
      'tw-m-0 tw-mr-2',
      'hover:tw-text-primary-500',
      'focus:tw-outline-none focus:tw-outline-offset-0 fo cus:tw-ring-1 focus:tw-ring-primary-500 focus:tw-z-10',
      'tw-cursor-pointer'
    ]
  },
  pcNextButton: {
    root: {
      class: [
        'tw-relative',
        'tw-inline-flex tw-items-center tw-justify-center',
        'tw-w-7 tw-h-7',
        'tw-p-0 tw-m-0',
        'tw-rounded-full',
        'tw-text-grey-500',
        'tw-border-0',
        'tw-bg-transparent',
        'tw-transition-colors tw-duration-200 tw-ease-in-out',
        'hover:tw-text-grey-800',
        'hover:tw-bg-grey-100',
        'focus:tw-outline-none focus:tw-outline-offset-0 focus:tw-ring-1 focus:tw-ring-primary-500 focus:tw-z-10',
        'tw-cursor-pointer tw-overflow-hidden'
      ]
    },
    label: {
      class: ['tw-hidden']
    }
  },
  dayView: {
    class: ['tw-text-sm tw-leading-[normal]', 'tw-border-collapse', 'tw-w-full', 'tw-m-0 tw-mt-2']
  },
  tableHeaderCell: {
    class: ['tw-p-1', 'tw-font-medium']
  },
  weekHeader: {
    class: ['tw-leading-5', 'tw-text-grey-500', 'tw-opacity-60 tw-cursor-default', 'tw-text-grey-600']
  },
  weekNumber: {
    class: ['tw-text-grey-500', 'tw-opacity-60 tw-cursor-default']
  },
  weekday: {
    class: ['tw-text-grey-500', 'tw-capitalize', 'tw-p-1']
  },
  dayCell: {
    class: ['tw-p-1']
  },
  weeklabelcontainer: ({ context }: Record<string, any>) => ({
    class: [
      // Flexbox and Alignment
      'tw-flex tw-items-center tw-justify-center',
      'tw-mx-auto',
      // Shape & Size
      'tw-w-8 tw-h-8',
      'tw-rounded-full',
      'tw-border-transparent tw-border',
      'tw-leading-[normal]',
      // Colors
      {
        'tw-text-grey-500 tw-bg-transparent': !context.selected && !context.disabled,
        'tw-text-primary-highlight-inverse tw-bg-primary-highlight': context.selected && !context.disabled
      },
      // States
      'focus:tw-outline-none focus:tw-outline-offset-0 focus:tw-ring-1 focus:tw-ring-primary-500 focus:tw-z-10',
      {
        'hover:tw-bg-teal': !context.selected && !context.disabled,
        'hover:tw-bg-primary-highlight-hover': context.selected && !context.disabled
      },
      {
        'tw-opacity-40 tw-cursor-default': context.disabled,
        'tw-cursor-pointer': !context.disabled
      }
    ]
  }),
  day: ({ context }: Record<string, any>) => ({
    class: [
      // Flexbox and Alignment
      'tw-flex tw-items-center tw-justify-center',
      'tw-mx-auto',
      // Shape & Size
      'tw-w-8 tw-h-8',
      'tw-rounded-full',
      'tw-border-transparent tw-border',
      'tw-leading-[normal]',
      // Colors
      {
        'tw-text-grey-900 tw-bg-grey-100': context.date.today && !context.selected && !context.disabled,
        'tw-bg-transparent tw-text-grey-700': !context.selected && !context.disabled && !context.date.today,
        'tw-text-white tw-bg-primary-500': context.selected && !context.disabled
      },
      // States
      'focus:tw-outline-none focus:tw-outline-offset-0 focus:tw-ring-1 focus:tw-ring-primary-500 focus:tw-z-10',
      {
        'hover:tw-bg-grey-200': !context.selected && !context.disabled
      },
      {
        'tw-opacity-40 tw-cursor-default': context.disabled,
        'tw-cursor-pointer': !context.disabled
      }
    ]
  }),
  monthPicker: {
    class: ['tw-mt-2']
  },
  month: ({ context }: Record<string, any>) => ({
    class: [
      // Flexbox and Alignment
      'tw-inline-flex tw-items-center tw-justify-center',
      // Size
      'tw-w-1/3',
      'tw-p-1',
      // Shape
      'tw-rounded-md',
      // Colors
      {
        'tw-text-grey-500 tw-bg-transparent': context.disabled,
        'tw-text-primary-highlight-inverse tw-bg-primary-highlight': context.selected && !context.disabled
      },
      // States
      'focus:tw-outline-none focus:tw-outline-offset-0 focus:tw-ring-1 focus:tw-ring-primary-500 focus:tw-z-10',
      // Misc
      'tw-cursor-pointer'
    ]
  }),
  yearPicker: {
    class: ['tw-mt-2']
  },
  year: ({ context }: Record<string, any>) => ({
    class: [
      // Flexbox and Alignment
      'tw-inline-flex tw-items-center tw-justify-center',
      // Size
      'tw-w-1/2',
      'tw-p-1',
      // Shape
      'tw-rounded-md',
      // Colors
      {
        'tw-text-grey-500 tw-bg-transparent': context.disabled,
        'tw-text-primary-highlight-inverse tw-bg-primary-highlight': context.selected && !context.disabled
      },
      // States
      'focus:tw-outline-none focus:tw-outline-offset-0 focus:tw-ring-1 focus:tw-ring-primary-500 focus:tw-z-10',
      // Misc
      'tw-cursor-pointer'
    ]
  }),
  timepicker: {
    class: [
      'tw-flex',
      'tw-justify-center tw-items-center',
      'tw-border-t-1',
      'tw-border-solid tw-border-grey-200',
      'tw-pt-2 tw-mt-2'
    ]
  },
  separatorContainer: {
    class: ['tw-flex', 'tw-items-center', 'tw-flex-col', 'tw-px-2']
  },
  separator: {
    class: ['tw-text-sm']
  },
  hourPicker: {
    class: ['tw-flex', 'tw-items-center', 'tw-flex-col', 'tw-px-2']
  },
  minutePicker: {
    class: ['tw-flex', 'tw-items-center', 'tw-flex-col', 'tw-px-2']
  },
  secondPicker: {
    class: ['tw-flex', 'tw-items-center', 'tw-flex-col', 'tw-px-2']
  },
  incrementButton: {
    class: [
      'tw-relative',
      'tw-inline-flex tw-items-center tw-justify-center',
      'tw-w-7 tw-h-7',
      'tw-p-0 tw-m-0',
      'tw-rounded-full',
      'tw-text-grey-500',
      'tw-border-0',
      'tw-bg-transparent',
      'tw-transition-colors tw-duration-200 tw-ease-in-out',
      'hover:tw-text-surface-700',
      'hover:tw-bg-surface-100',
      'focus:tw-outline-none focus:tw-outline-offset-0 focus:tw-ring-1 focus:tw-ring-primary-500 focus:tw-z-10',
      'tw-cursor-pointer tw-overflow-hidden'
    ]
  },
  decrementButton: {
    class: [
      'tw-relative',
      'tw-inline-flex tw-items-center tw-justify-center',
      'tw-w-7 tw-h-7',
      'tw-p-0 tw-m-0',
      'tw-rounded-full',
      'tw-text-grey-500',
      'tw-border-0',
      'tw-bg-transparent',
      'tw-transition-colors tw-duration-200 tw-ease-in-out',
      'hover:tw-text-surface-700',
      'hover:tw-bg-surface-100',
      'focus:tw-outline-none focus:tw-outline-offset-0 focus:tw-ring-1 focus:tw-ring-primary-500 focus:tw-z-10',
      'tw-cursor-pointer tw-overflow-hidden'
    ]
  },
  calendarContainer: {
    class: ['tw-flex']
  },
  calendar: {
    class: [
      'tw-flex-1',
      'tw-border-l',
      'tw-border-grey-200',
      'tw-pr-0.5',
      'tw-pl-0.5',
      'tw-pt-0',
      'tw-pb-0',
      'first:tw-pl-0',
      'first:tw-border-l-0'
    ]
  },
  buttonbar: ({ attrs }: Record<string, any>) => ({
    class: [
      'tw-flex tw-justify-between tw-items-center',
      'tw-pt-2',
      'tw-border-t tw-border-grey-200',
      { 'tw-hidden': !attrs['show-today-button'] && !attrs.clearable }
    ]
  }),
  pcTodayButton: ({ attrs }: Record<string, any>) => ({
    root: {
      class: [
        'tw-inline-flex tw-items-center tw-justify-center',
        'tw-px-3 tw-py-1 tw-text-sm tw-leading-[normal]',
        'tw-rounded-md',
        'tw-bg-transparent tw-border-transparent',
        'tw-text-primary',
        'tw-transition-colors tw-duration-200 tw-ease-in-out',
        'focus:tw-outline-none focus:tw-outline-offset-0 focus:tw-ring-1 focus:tw-ring-primary-500 focus:tw-z-10',
        'hover:tw-bg-primary-300/20',
        'tw-cursor-pointer',
        {
          'tw-hidden': !attrs['show-today-button']
        }
      ]
    }
  }),
  pcClearButton: ({ attrs }: Record<string, any>) => ({
    root: {
      class: [
        'tw-inline-flex tw-items-center tw-justify-center',
        'tw-px-3 tw-py-1 tw-text-sm tw-leading-[normal]',
        'tw-rounded-md',
        'tw-bg-transparent tw-border-transparent',
        'tw-text-primary',
        'tw-transition-colors tw-duration-200 tw-ease-in-out',
        'focus:tw-outline-none focus:tw-outline-offset-0 focus:tw-ring-1 focus:tw-ring-primary-500 focus:tw-z-10',
        'hover:tw-bg-primary-300/20',
        'tw-cursor-pointer',
        {
          'tw-hidden': !attrs.clearable,
          'tw-ml-auto': !attrs['show-today-button']
        }
      ]
    }
  }),
  transition: {
    enterFromClass: 'tw-opacity-0 tw-scale-y-[0.8]',
    enterActiveClass: 'tw-transition-[transform,opacity] tw-duration-[120ms] tw-ease-[cubic-bezier(0,0,0.2,1)]',
    leaveActiveClass: 'tw-transition-opacity tw-duration-100 tw-ease-linear',
    leaveToClass: 'tw-opacity-0'
  }
};
