<script lang="ts" setup>
import { hasPermission, useClaims } from '@auth';
import { SUREbusinessLogo } from '@assets/icons';
import { menuQueries } from '@services/asp-c/queries/Menu';
import {
  SUREbusinessCommonDomainAdviseurStatus as AdviseurStatus,
  useAdviseursGetHuidigeAdviseur
} from '@services/crm-public/orval-api';
import { VMenu, type VMenuItem, VSkeleton } from '@shared/component';
import { FontAwesomeIcon } from '@shared/plugin';
import { useQuery } from '@tanstack/vue-query';
import type { MenuItemCommandEvent } from 'primevue/menuitem';
import { computed, defineModel } from 'vue';
import { useFeatureToggle } from '@environment-lib';

import { HeaderItem, LinkItem, MenuItem } from './types';
import { getSurenetUrl, tryMenuNavigation } from '../../urlHelper';
import AppLayoutContact from './AppLayoutContact.vue';
import AppLayoutMenuAccount from './AppLayoutMenuAccount.vue';
import AppLayoutMenuIntern from './AppLayoutMenuIntern.vue';
import { createMenuItems } from './mainMenuItems';

const props = defineProps<{
  customItems?: Array<MenuItem>;
  customLoadingState?: boolean;
}>();

const open = defineModel<boolean>('open');
const searchUserOpen = defineModel<boolean>('searchUserOpen');

const claims = useClaims();
const inspectieAanvragenV2Toggle = useFeatureToggle('InspectieAanvragenV2Toggle');

const tellingenQuery = useQuery({
  ...menuQueries.getTellingen,
  select: result => result.data,
  enabled: computed(() => claims.value?.isSuccess && !props.customItems)
});

const aantalPremievastellingQuery = useQuery({
  ...menuQueries.getAantalPremievastellingen,
  select: result => result.data,
  enabled: computed(() => claims.value?.isSuccess && !props.customItems)
});

const huidigeAdviseur = useAdviseursGetHuidigeAdviseur({ query: { select: data => data.result } });

const heeftMutatieStartenPermissie = hasPermission('Muteren.MutatieStarten');
const heeftRoyerenPermissie = hasPermission('Muteren.Royeren');

const menuItemsHoofdMenu = computed(() => {
  return createMenuItems({
    isMaatschappij: claims.value?.isMaatschappij ?? false,
    isInterneGebruiker: claims.value?.isInternal ?? false,
    isFinancieel: claims.value?.isFinancieel ?? false,
    isInstellingsRechten: claims.value?.heeftInstellingenPermissie,
    isActiveAdviseur:
      (claims.value?.isAdviseur ?? false) && huidigeAdviseur.data.value?.status === AdviseurStatus.Actief,
    heeftAdviseurbeheerRechten: claims.value?.magAdviseurbeheerInzien ?? false,
    heeftBeheerboxRechten: claims.value?.magBeheerboxInzien ?? false,
    heeftMutatieStartenPermissie: heeftMutatieStartenPermissie.value ?? false,
    heeftRoyerenPermissie: heeftRoyerenPermissie.value ?? false,
    inspectieAanvragenV2Toggle: inspectieAanvragenV2Toggle.value ?? false,
    tellingen: { ...tellingenQuery.data.value, aantalPremievastellingen: aantalPremievastellingQuery.data.value }
  });
});

const menuItems = computed(() => props.customItems || menuItemsHoofdMenu.value || []);
const model = computed(() => menuItems.value.reduce(menuItemsReducer, []));

function closeMenu() {
  open.value = false;
}

function menuItemClicked(url: string) {
  return ({ originalEvent }: MenuItemCommandEvent) => {
    if (!tryMenuNavigation(originalEvent, url)) {
      return;
    }

    open.value = false;
  };
}

const parseMenuItem = ({ badgecontent, iconclass, showbadge, target, title, url }: LinkItem): VMenuItem => ({
  badge: showbadge ? badgecontent?.toString() : '',
  command: !target ? menuItemClicked(url) : undefined,
  icon: iconclass,
  label: title,
  target,
  url
});

const parseMenuTitle = ({ title }: HeaderItem) => ({
  items: [],
  label: title
});

function menuItemsReducer(items: VMenuItem[], item: MenuItem) {
  if (item.itemtype === 'header') {
    items.push(parseMenuTitle(item));
  } else {
    const [lastRootItem] = items.slice(-1);
    (lastRootItem?.items ? lastRootItem.items : items).push(parseMenuItem(item));
  }

  return items;
}

const isLoading = computed(
  () =>
    (props.customItems ? props.customLoadingState : claims.value.isInitialLoading) || huidigeAdviseur.isPending.value
);
</script>

<template>
  <nav
    class="tw-fixed max-3xl:tw-inset-0 max-3xl:tw-z-header 3xl:tw-sticky 3xl:tw-top-[var(--header-height,0)] 3xl:tw-h-[calc(100dvh-var(--header-height,0px))]"
    :class="{
      'max-3xl:tw-pointer-events-none max-3xl:tw-opacity-0 max-3xl:tw-transition-[clip-path,opacity] max-3xl:tw-delay-[.4s,0s] max-3xl:tw-duration-[0s,.4s] max-3xl:tw-clip-path-[circle(0_at_1.5rem_calc(var(--header-height,0px)/2))]':
        !open,
      'max-3xl:tw-transition-[clip-path] max-3xl:tw-duration-400 max-3xl:tw-clip-path-[circle(calc(hypot(100dvw,100dvh)/2))]':
        open
    }">
    <div
      class="tw-flex tw-h-full tw-flex-col tw-gap-5 tw-overflow-y-auto tw-bg-primary-800 tw-text-white tw-scrollbar-light tw-scrollbar-thin sm:tw-w-[340px] 3xl:tw-w-auto">
      <header
        class="tw-sticky tw-top-0 tw-z-10 tw-flex tw-shrink-0 tw-flex-col tw-gap-5 tw-bg-inherit tw-pb-2 3xl:tw-hidden">
        <div class="tw-grid tw-h-[var(--header-height,auto)] tw-grid-cols-[1fr,auto,1fr] tw-gap-2 tw-px-2 lg:tw-px-3">
          <div class="tw-flex">
            <button
              class="tw-border-none tw-bg-transparent tw-px-3 tw-text-lg tw-text-inherit tw-outline-none"
              type="button"
              @click="closeMenu">
              <FontAwesomeIcon :icon="['fas', 'chevron-left']" />
            </button>
          </div>

          <a class="tw-flex tw-items-center tw-text-inherit" :href="getSurenetUrl('/content/', false)">
            <SUREbusinessLogo class="tw-h-6 -tw-translate-y-[5px]" theme="light" />
          </a>

          <div class="tw-flex tw-justify-end tw-py-4">
            <AppLayoutMenuIntern
              v-if="claims?.isInternal"
              v-model:searchUserOpen="searchUserOpen"
              always-small
              icon-style="fas" />

            <AppLayoutMenuAccount always-small icon-style="fas" />
          </div>
        </div>

        <div class="tw-flex tw-shrink-0 tw-px-4 max-sm:tw-justify-center">
          <AppLayoutContact class="tw-p-2 tw-text-white/70" />
        </div>
      </header>

      <div class="tw-grow tw-pb-1 max-3xl:tw-px-2 3xl:tw-py-8">
        <VSkeleton v-if="isLoading" :count="7" />

        <VMenu v-else class="tw-w-full" :model theme="primary" />
      </div>
    </div>

    <aside
      class="tw-absolute tw-inset-0 -tw-z-10 tw-cursor-pointer tw-bg-black/50 tw-backdrop-blur-sm 3xl:tw-hidden"
      @click="closeMenu"></aside>
  </nav>
</template>
