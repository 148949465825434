/* tslint:disable */
/* eslint-disable */
/**
 * SUREnet overig API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface CollectionResultOfSUREbusinessAuditingEntitiesAuditBeheerboxProductLog
 */
export interface CollectionResultOfSUREbusinessAuditingEntitiesAuditBeheerboxProductLog {
    /**
     * 
     * @type {Array<SUREbusinessAuditingEntitiesAuditBeheerboxProductLog>}
     * @memberof CollectionResultOfSUREbusinessAuditingEntitiesAuditBeheerboxProductLog
     */
    result?: Array<SUREbusinessAuditingEntitiesAuditBeheerboxProductLog> | null;
    /**
     * 
     * @type {number}
     * @memberof CollectionResultOfSUREbusinessAuditingEntitiesAuditBeheerboxProductLog
     */
    status: number;
    /**
     * 
     * @type {ManagementModelsApiError}
     * @memberof CollectionResultOfSUREbusinessAuditingEntitiesAuditBeheerboxProductLog
     */
    error: ManagementModelsApiError;
}
/**
 * 
 * @export
 * @interface CollectionResultOfSUREbusinessCCSReadingOdbcGetTabellenTabelDto
 */
export interface CollectionResultOfSUREbusinessCCSReadingOdbcGetTabellenTabelDto {
    /**
     * 
     * @type {Array<SUREbusinessCCSReadingOdbcGetTabellenTabelDto>}
     * @memberof CollectionResultOfSUREbusinessCCSReadingOdbcGetTabellenTabelDto
     */
    result?: Array<SUREbusinessCCSReadingOdbcGetTabellenTabelDto> | null;
    /**
     * 
     * @type {number}
     * @memberof CollectionResultOfSUREbusinessCCSReadingOdbcGetTabellenTabelDto
     */
    status: number;
    /**
     * 
     * @type {ManagementModelsApiError}
     * @memberof CollectionResultOfSUREbusinessCCSReadingOdbcGetTabellenTabelDto
     */
    error: ManagementModelsApiError;
}
/**
 * 
 * @export
 * @interface CollectionResultOfSUREbusinessManagementKvkKvkBedrijfDto
 */
export interface CollectionResultOfSUREbusinessManagementKvkKvkBedrijfDto {
    /**
     * 
     * @type {Array<SUREbusinessManagementKvkKvkBedrijfDto>}
     * @memberof CollectionResultOfSUREbusinessManagementKvkKvkBedrijfDto
     */
    result?: Array<SUREbusinessManagementKvkKvkBedrijfDto> | null;
    /**
     * 
     * @type {number}
     * @memberof CollectionResultOfSUREbusinessManagementKvkKvkBedrijfDto
     */
    status: number;
    /**
     * 
     * @type {ManagementModelsApiError}
     * @memberof CollectionResultOfSUREbusinessManagementKvkKvkBedrijfDto
     */
    error: ManagementModelsApiError;
}
/**
 * 
 * @export
 * @interface CollectionResultOfSUREbusinessManagementLandenGetLandenLandDto
 */
export interface CollectionResultOfSUREbusinessManagementLandenGetLandenLandDto {
    /**
     * 
     * @type {Array<SUREbusinessManagementLandenGetLandenLandDto>}
     * @memberof CollectionResultOfSUREbusinessManagementLandenGetLandenLandDto
     */
    result?: Array<SUREbusinessManagementLandenGetLandenLandDto> | null;
    /**
     * 
     * @type {number}
     * @memberof CollectionResultOfSUREbusinessManagementLandenGetLandenLandDto
     */
    status: number;
    /**
     * 
     * @type {ManagementModelsApiError}
     * @memberof CollectionResultOfSUREbusinessManagementLandenGetLandenLandDto
     */
    error: ManagementModelsApiError;
}
/**
 * 
 * @export
 * @interface CollectionResultOfSUREbusinessManagementOnderhoudDtosOnderhoudsmeldingDto
 */
export interface CollectionResultOfSUREbusinessManagementOnderhoudDtosOnderhoudsmeldingDto {
    /**
     * 
     * @type {Array<SUREbusinessManagementOnderhoudDtosOnderhoudsmeldingDto>}
     * @memberof CollectionResultOfSUREbusinessManagementOnderhoudDtosOnderhoudsmeldingDto
     */
    result?: Array<SUREbusinessManagementOnderhoudDtosOnderhoudsmeldingDto> | null;
    /**
     * 
     * @type {number}
     * @memberof CollectionResultOfSUREbusinessManagementOnderhoudDtosOnderhoudsmeldingDto
     */
    status: number;
    /**
     * 
     * @type {ManagementModelsApiError}
     * @memberof CollectionResultOfSUREbusinessManagementOnderhoudDtosOnderhoudsmeldingDto
     */
    error: ManagementModelsApiError;
}
/**
 * 
 * @export
 * @interface CollectionResultOfSUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto
 */
export interface CollectionResultOfSUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto {
    /**
     * 
     * @type {Array<SUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto>}
     * @memberof CollectionResultOfSUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto
     */
    result?: Array<SUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto> | null;
    /**
     * 
     * @type {number}
     * @memberof CollectionResultOfSUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto
     */
    status: number;
    /**
     * 
     * @type {ManagementModelsApiError}
     * @memberof CollectionResultOfSUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto
     */
    error: ManagementModelsApiError;
}
/**
 * 
 * @export
 * @interface CollectionResultOfSUREbusinessManagementOnderhoudDtosOnderhoudsvensterLogDto
 */
export interface CollectionResultOfSUREbusinessManagementOnderhoudDtosOnderhoudsvensterLogDto {
    /**
     * 
     * @type {Array<SUREbusinessManagementOnderhoudDtosOnderhoudsvensterLogDto>}
     * @memberof CollectionResultOfSUREbusinessManagementOnderhoudDtosOnderhoudsvensterLogDto
     */
    result?: Array<SUREbusinessManagementOnderhoudDtosOnderhoudsvensterLogDto> | null;
    /**
     * 
     * @type {number}
     * @memberof CollectionResultOfSUREbusinessManagementOnderhoudDtosOnderhoudsvensterLogDto
     */
    status: number;
    /**
     * 
     * @type {ManagementModelsApiError}
     * @memberof CollectionResultOfSUREbusinessManagementOnderhoudDtosOnderhoudsvensterLogDto
     */
    error: ManagementModelsApiError;
}
/**
 * 
 * @export
 * @interface CollectionResultOfSUREbusinessManagementReportsGetAvailableReportsDto
 */
export interface CollectionResultOfSUREbusinessManagementReportsGetAvailableReportsDto {
    /**
     * 
     * @type {Array<SUREbusinessManagementReportsGetAvailableReportsDto>}
     * @memberof CollectionResultOfSUREbusinessManagementReportsGetAvailableReportsDto
     */
    result?: Array<SUREbusinessManagementReportsGetAvailableReportsDto> | null;
    /**
     * 
     * @type {number}
     * @memberof CollectionResultOfSUREbusinessManagementReportsGetAvailableReportsDto
     */
    status: number;
    /**
     * 
     * @type {ManagementModelsApiError}
     * @memberof CollectionResultOfSUREbusinessManagementReportsGetAvailableReportsDto
     */
    error: ManagementModelsApiError;
}
/**
 * 
 * @export
 * @interface CollectionResultOfSUREbusinessManagementSearchSearchDto
 */
export interface CollectionResultOfSUREbusinessManagementSearchSearchDto {
    /**
     * 
     * @type {Array<SUREbusinessManagementSearchSearchDto>}
     * @memberof CollectionResultOfSUREbusinessManagementSearchSearchDto
     */
    result?: Array<SUREbusinessManagementSearchSearchDto> | null;
    /**
     * 
     * @type {number}
     * @memberof CollectionResultOfSUREbusinessManagementSearchSearchDto
     */
    status: number;
    /**
     * 
     * @type {ManagementModelsApiError}
     * @memberof CollectionResultOfSUREbusinessManagementSearchSearchDto
     */
    error: ManagementModelsApiError;
}
/**
 * 
 * @export
 * @interface CollectionResultOfSUREbusinessManagementToolsQuerytoolDtoOdbcQueryDto
 */
export interface CollectionResultOfSUREbusinessManagementToolsQuerytoolDtoOdbcQueryDto {
    /**
     * 
     * @type {Array<SUREbusinessManagementToolsQuerytoolDtoOdbcQueryDto>}
     * @memberof CollectionResultOfSUREbusinessManagementToolsQuerytoolDtoOdbcQueryDto
     */
    result?: Array<SUREbusinessManagementToolsQuerytoolDtoOdbcQueryDto> | null;
    /**
     * 
     * @type {number}
     * @memberof CollectionResultOfSUREbusinessManagementToolsQuerytoolDtoOdbcQueryDto
     */
    status: number;
    /**
     * 
     * @type {ManagementModelsApiError}
     * @memberof CollectionResultOfSUREbusinessManagementToolsQuerytoolDtoOdbcQueryDto
     */
    error: ManagementModelsApiError;
}
/**
 * 
 * @export
 * @interface CollectionResultOfSUREbusinessManagementToolsQuerytoolDtoQueryTable
 */
export interface CollectionResultOfSUREbusinessManagementToolsQuerytoolDtoQueryTable {
    /**
     * 
     * @type {Array<SUREbusinessManagementToolsQuerytoolDtoQueryTable>}
     * @memberof CollectionResultOfSUREbusinessManagementToolsQuerytoolDtoQueryTable
     */
    result?: Array<SUREbusinessManagementToolsQuerytoolDtoQueryTable> | null;
    /**
     * 
     * @type {number}
     * @memberof CollectionResultOfSUREbusinessManagementToolsQuerytoolDtoQueryTable
     */
    status: number;
    /**
     * 
     * @type {ManagementModelsApiError}
     * @memberof CollectionResultOfSUREbusinessManagementToolsQuerytoolDtoQueryTable
     */
    error: ManagementModelsApiError;
}
/**
 * 
 * @export
 * @interface CollectionResultOfVragenVoertuigGetVoertuigDataByKentekenVoertuigDto
 */
export interface CollectionResultOfVragenVoertuigGetVoertuigDataByKentekenVoertuigDto {
    /**
     * 
     * @type {Array<VragenVoertuigGetVoertuigDataByKentekenVoertuigDto>}
     * @memberof CollectionResultOfVragenVoertuigGetVoertuigDataByKentekenVoertuigDto
     */
    result?: Array<VragenVoertuigGetVoertuigDataByKentekenVoertuigDto> | null;
    /**
     * 
     * @type {number}
     * @memberof CollectionResultOfVragenVoertuigGetVoertuigDataByKentekenVoertuigDto
     */
    status: number;
    /**
     * 
     * @type {ManagementModelsApiError}
     * @memberof CollectionResultOfVragenVoertuigGetVoertuigDataByKentekenVoertuigDto
     */
    error: ManagementModelsApiError;
}
/**
 * 
 * @export
 * @interface CrmTussenPersonenGetAdviseurBrandingBrandingDto
 */
export interface CrmTussenPersonenGetAdviseurBrandingBrandingDto {
    /**
     * 
     * @type {string}
     * @memberof CrmTussenPersonenGetAdviseurBrandingBrandingDto
     */
    kantoornaam: string;
    /**
     * 
     * @type {string}
     * @memberof CrmTussenPersonenGetAdviseurBrandingBrandingDto
     */
    website: string;
    /**
     * 
     * @type {string}
     * @memberof CrmTussenPersonenGetAdviseurBrandingBrandingDto
     */
    telefoonnummer: string;
    /**
     * 
     * @type {string}
     * @memberof CrmTussenPersonenGetAdviseurBrandingBrandingDto
     */
    emailadres: string;
    /**
     * 
     * @type {string}
     * @memberof CrmTussenPersonenGetAdviseurBrandingBrandingDto
     */
    logo?: string | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof CrmTussenPersonenGetAdviseurBrandingBrandingDto
     */
    variables: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface ManagementAPIOverigEnvironmentDto
 */
export interface ManagementAPIOverigEnvironmentDto {
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof ManagementAPIOverigEnvironmentDto
     */
    featureFlags?: { [key: string]: boolean; } | null;
}
/**
 * 
 * @export
 * @interface ManagementAPIOverigQueryToolApiControllerUpsertQuery
 */
export interface ManagementAPIOverigQueryToolApiControllerUpsertQuery {
    /**
     * 
     * @type {string}
     * @memberof ManagementAPIOverigQueryToolApiControllerUpsertQuery
     */
    naam?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManagementAPIOverigQueryToolApiControllerUpsertQuery
     */
    query?: string | null;
    /**
     * 
     * @type {SUREbusinessManagementToolsQuerytoolSelectedDatabaseEnum}
     * @memberof ManagementAPIOverigQueryToolApiControllerUpsertQuery
     */
    database: SUREbusinessManagementToolsQuerytoolSelectedDatabaseEnum;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum ManagementAPIOverigSpfControllerSpfStatus {
    Ok = 'Ok',
    Outdated = 'Outdated',
    Fail = 'Fail'
}

/**
 * 
 * @export
 * @interface ManagementAPIOverigUserClaimDto
 */
export interface ManagementAPIOverigUserClaimDto {
    /**
     * 
     * @type {string}
     * @memberof ManagementAPIOverigUserClaimDto
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManagementAPIOverigUserClaimDto
     */
    value?: string | null;
}
/**
 * 
 * @export
 * @interface ManagementAPIOverigVoertuigControllerDebugInfoDto
 */
export interface ManagementAPIOverigVoertuigControllerDebugInfoDto {
    /**
     * 
     * @type {any}
     * @memberof ManagementAPIOverigVoertuigControllerDebugInfoDto
     */
    jsonResponse: any | null;
    /**
     * 
     * @type {any}
     * @memberof ManagementAPIOverigVoertuigControllerDebugInfoDto
     */
    voertuigData: any | null;
}
/**
 * 
 * @export
 * @interface ManagementAPIOverigVoertuigControllerDebugInfoZoekopdracht
 */
export interface ManagementAPIOverigVoertuigControllerDebugInfoZoekopdracht {
    /**
     * 
     * @type {string}
     * @memberof ManagementAPIOverigVoertuigControllerDebugInfoZoekopdracht
     */
    kenteken?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ManagementAPIOverigVoertuigControllerDebugInfoZoekopdracht
     */
    ophalen: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ManagementAPIOverigVoertuigControllerDebugInfoZoekopdracht
     */
    callTestEndpoint: boolean;
    /**
     * 
     * @type {string}
     * @memberof ManagementAPIOverigVoertuigControllerDebugInfoZoekopdracht
     */
    meldcode?: string | null;
}
/**
 * 
 * @export
 * @interface ManagementModelsApiError
 */
export interface ManagementModelsApiError {
    /**
     * 
     * @type {string}
     * @memberof ManagementModelsApiError
     */
    message?: string | null;
}
/**
 * 
 * @export
 * @interface Result1OfCrmTussenPersonenGetAdviseurBrandingBrandingDto
 */
export interface Result1OfCrmTussenPersonenGetAdviseurBrandingBrandingDto {
    /**
     * 
     * @type {CrmTussenPersonenGetAdviseurBrandingBrandingDto}
     * @memberof Result1OfCrmTussenPersonenGetAdviseurBrandingBrandingDto
     */
    result: CrmTussenPersonenGetAdviseurBrandingBrandingDto;
    /**
     * 
     * @type {number}
     * @memberof Result1OfCrmTussenPersonenGetAdviseurBrandingBrandingDto
     */
    status: number;
    /**
     * 
     * @type {ManagementModelsApiError}
     * @memberof Result1OfCrmTussenPersonenGetAdviseurBrandingBrandingDto
     */
    error: ManagementModelsApiError;
}
/**
 * 
 * @export
 * @interface Result1OfManagementAPIOverigVoertuigControllerDebugInfoDto
 */
export interface Result1OfManagementAPIOverigVoertuigControllerDebugInfoDto {
    /**
     * 
     * @type {ManagementAPIOverigVoertuigControllerDebugInfoDto}
     * @memberof Result1OfManagementAPIOverigVoertuigControllerDebugInfoDto
     */
    result: ManagementAPIOverigVoertuigControllerDebugInfoDto;
    /**
     * 
     * @type {number}
     * @memberof Result1OfManagementAPIOverigVoertuigControllerDebugInfoDto
     */
    status: number;
    /**
     * 
     * @type {ManagementModelsApiError}
     * @memberof Result1OfManagementAPIOverigVoertuigControllerDebugInfoDto
     */
    error: ManagementModelsApiError;
}
/**
 * 
 * @export
 * @interface Result1OfSUREbusinessManagementAanvragenInstellingenAanvraagInstellingenDto
 */
export interface Result1OfSUREbusinessManagementAanvragenInstellingenAanvraagInstellingenDto {
    /**
     * 
     * @type {SUREbusinessManagementAanvragenInstellingenAanvraagInstellingenDto}
     * @memberof Result1OfSUREbusinessManagementAanvragenInstellingenAanvraagInstellingenDto
     */
    result: SUREbusinessManagementAanvragenInstellingenAanvraagInstellingenDto;
    /**
     * 
     * @type {number}
     * @memberof Result1OfSUREbusinessManagementAanvragenInstellingenAanvraagInstellingenDto
     */
    status: number;
    /**
     * 
     * @type {ManagementModelsApiError}
     * @memberof Result1OfSUREbusinessManagementAanvragenInstellingenAanvraagInstellingenDto
     */
    error: ManagementModelsApiError;
}
/**
 * 
 * @export
 * @interface Result1OfSUREbusinessManagementKvkKvkBedrijfsProfielDto
 */
export interface Result1OfSUREbusinessManagementKvkKvkBedrijfsProfielDto {
    /**
     * 
     * @type {SUREbusinessManagementKvkKvkBedrijfsProfielDto}
     * @memberof Result1OfSUREbusinessManagementKvkKvkBedrijfsProfielDto
     */
    result: SUREbusinessManagementKvkKvkBedrijfsProfielDto;
    /**
     * 
     * @type {number}
     * @memberof Result1OfSUREbusinessManagementKvkKvkBedrijfsProfielDto
     */
    status: number;
    /**
     * 
     * @type {ManagementModelsApiError}
     * @memberof Result1OfSUREbusinessManagementKvkKvkBedrijfsProfielDto
     */
    error: ManagementModelsApiError;
}
/**
 * 
 * @export
 * @interface Result1OfSUREbusinessManagementOfferteOfferteCompareDto
 */
export interface Result1OfSUREbusinessManagementOfferteOfferteCompareDto {
    /**
     * 
     * @type {SUREbusinessManagementOfferteOfferteCompareDto}
     * @memberof Result1OfSUREbusinessManagementOfferteOfferteCompareDto
     */
    result: SUREbusinessManagementOfferteOfferteCompareDto;
    /**
     * 
     * @type {number}
     * @memberof Result1OfSUREbusinessManagementOfferteOfferteCompareDto
     */
    status: number;
    /**
     * 
     * @type {ManagementModelsApiError}
     * @memberof Result1OfSUREbusinessManagementOfferteOfferteCompareDto
     */
    error: ManagementModelsApiError;
}
/**
 * 
 * @export
 * @interface Result1OfSUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto
 */
export interface Result1OfSUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto {
    /**
     * 
     * @type {SUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto}
     * @memberof Result1OfSUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto
     */
    result: SUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto;
    /**
     * 
     * @type {number}
     * @memberof Result1OfSUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto
     */
    status: number;
    /**
     * 
     * @type {ManagementModelsApiError}
     * @memberof Result1OfSUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto
     */
    error: ManagementModelsApiError;
}
/**
 * 
 * @export
 * @interface Result1OfSUREbusinessManagementReportsTableDefintion1OfSystemObject
 */
export interface Result1OfSUREbusinessManagementReportsTableDefintion1OfSystemObject {
    /**
     * 
     * @type {SUREbusinessManagementReportsTableDefintion1OfSystemObject}
     * @memberof Result1OfSUREbusinessManagementReportsTableDefintion1OfSystemObject
     */
    result: SUREbusinessManagementReportsTableDefintion1OfSystemObject;
    /**
     * 
     * @type {number}
     * @memberof Result1OfSUREbusinessManagementReportsTableDefintion1OfSystemObject
     */
    status: number;
    /**
     * 
     * @type {ManagementModelsApiError}
     * @memberof Result1OfSUREbusinessManagementReportsTableDefintion1OfSystemObject
     */
    error: ManagementModelsApiError;
}
/**
 * 
 * @export
 * @interface Result1OfSUREbusinessManagementToolsQuerytoolDtoTableReference
 */
export interface Result1OfSUREbusinessManagementToolsQuerytoolDtoTableReference {
    /**
     * 
     * @type {SUREbusinessManagementToolsQuerytoolDtoTableReference}
     * @memberof Result1OfSUREbusinessManagementToolsQuerytoolDtoTableReference
     */
    result: SUREbusinessManagementToolsQuerytoolDtoTableReference;
    /**
     * 
     * @type {number}
     * @memberof Result1OfSUREbusinessManagementToolsQuerytoolDtoTableReference
     */
    status: number;
    /**
     * 
     * @type {ManagementModelsApiError}
     * @memberof Result1OfSUREbusinessManagementToolsQuerytoolDtoTableReference
     */
    error: ManagementModelsApiError;
}
/**
 * 
 * @export
 * @interface Result1OfSystemBoolean
 */
export interface Result1OfSystemBoolean {
    /**
     * 
     * @type {boolean}
     * @memberof Result1OfSystemBoolean
     */
    result: boolean;
    /**
     * 
     * @type {number}
     * @memberof Result1OfSystemBoolean
     */
    status: number;
    /**
     * 
     * @type {ManagementModelsApiError}
     * @memberof Result1OfSystemBoolean
     */
    error: ManagementModelsApiError;
}
/**
 * 
 * @export
 * @interface Result1OfSystemInt32
 */
export interface Result1OfSystemInt32 {
    /**
     * 
     * @type {number}
     * @memberof Result1OfSystemInt32
     */
    result: number;
    /**
     * 
     * @type {number}
     * @memberof Result1OfSystemInt32
     */
    status: number;
    /**
     * 
     * @type {ManagementModelsApiError}
     * @memberof Result1OfSystemInt32
     */
    error: ManagementModelsApiError;
}
/**
 * 
 * @export
 * @interface SUREbusinessAuditingEntitiesAuditBeheerboxProductLog
 */
export interface SUREbusinessAuditingEntitiesAuditBeheerboxProductLog {
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessAuditingEntitiesAuditBeheerboxProductLog
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessAuditingEntitiesAuditBeheerboxProductLog
     */
    userEmail: string;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessAuditingEntitiesAuditBeheerboxProductLog
     */
    occurredOn: string;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessAuditingEntitiesAuditBeheerboxProductLog
     */
    productId: string;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessAuditingEntitiesAuditBeheerboxProductLog
     */
    productmaatschappijId?: string | null;
    /**
     * 
     * @type {SUREbusinessAuditingEntitiesAuditBeheerboxProductOnderdeel}
     * @memberof SUREbusinessAuditingEntitiesAuditBeheerboxProductLog
     */
    auditBeheerboxProductOnderdeel: SUREbusinessAuditingEntitiesAuditBeheerboxProductOnderdeel;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessAuditingEntitiesAuditBeheerboxProductLog
     */
    logTekst?: string | null;
    /**
     * 
     * @type {SUREbusinessAuditingEntitiesSoortAuditLog}
     * @memberof SUREbusinessAuditingEntitiesAuditBeheerboxProductLog
     */
    soort: SUREbusinessAuditingEntitiesSoortAuditLog;
    /**
     * 
     * @type {boolean}
     * @memberof SUREbusinessAuditingEntitiesAuditBeheerboxProductLog
     */
    verwijderd: boolean;
    /**
     * 
     * @type {Array<SUREbusinessAuditingEntitiesChangeEntity>}
     * @memberof SUREbusinessAuditingEntitiesAuditBeheerboxProductLog
     */
    entities: Array<SUREbusinessAuditingEntitiesChangeEntity>;
    /**
     * 
     * @type {number}
     * @memberof SUREbusinessAuditingEntitiesAuditBeheerboxProductLog
     */
    id: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum SUREbusinessAuditingEntitiesAuditBeheerboxProductOnderdeel {
    None = 'None',
    ProductVragen = 'ProductVragen',
    Bepalingverzekerdbedrag = 'Bepalingverzekerdbedrag',
    Bepalingeigenrisico = 'Bepalingeigenrisico',
    Maatschappijdocument = 'Maatschappijdocument',
    Acceptatiecontrole = 'Acceptatiecontrole',
    Mapping = 'Mapping',
    Vergelijkingscriterium = 'Vergelijkingscriterium',
    Maatschappij = 'Maatschappij',
    Product = 'Product',
    VergelijkingsWaarde = 'VergelijkingsWaarde',
    Bijzonderheden = 'Bijzonderheden'
}

/**
 * 
 * @export
 * @interface SUREbusinessAuditingEntitiesChangeEntity
 */
export interface SUREbusinessAuditingEntitiesChangeEntity {
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessAuditingEntitiesChangeEntity
     */
    tableName: string;
    /**
     * 
     * @type {SystemCollectionsGenericKeyValuePair2OfSystemStringAndSystemString}
     * @memberof SUREbusinessAuditingEntitiesChangeEntity
     */
    primaryKey: SystemCollectionsGenericKeyValuePair2OfSystemStringAndSystemString;
    /**
     * 
     * @type {SUREbusinessAuditingEntitiesEntityAction}
     * @memberof SUREbusinessAuditingEntitiesChangeEntity
     */
    action: SUREbusinessAuditingEntitiesEntityAction;
    /**
     * 
     * @type {Array<SUREbusinessAuditingEntitiesChangeProperty>}
     * @memberof SUREbusinessAuditingEntitiesChangeEntity
     */
    changeProperties: Array<SUREbusinessAuditingEntitiesChangeProperty>;
}
/**
 * 
 * @export
 * @interface SUREbusinessAuditingEntitiesChangeProperty
 */
export interface SUREbusinessAuditingEntitiesChangeProperty {
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessAuditingEntitiesChangeProperty
     */
    fieldName: string;
    /**
     * 
     * @type {any}
     * @memberof SUREbusinessAuditingEntitiesChangeProperty
     */
    orignalValue?: any | null;
    /**
     * 
     * @type {any}
     * @memberof SUREbusinessAuditingEntitiesChangeProperty
     */
    newValue?: any | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum SUREbusinessAuditingEntitiesEntityAction {
    Unchanged = 'Unchanged',
    Deleted = 'Deleted',
    Modified = 'Modified',
    Added = 'Added'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum SUREbusinessAuditingEntitiesSoortAuditLog {
    None = 'None',
    Log = 'Log',
    Memo = 'Memo'
}

/**
 * 
 * @export
 * @interface SUREbusinessCCSPublisherFeaturesRelatieRelatiePublishJobVerwerkMutatiesResult
 */
export interface SUREbusinessCCSPublisherFeaturesRelatieRelatiePublishJobVerwerkMutatiesResult {
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessCCSPublisherFeaturesRelatieRelatiePublishJobVerwerkMutatiesResult
     */
    lastSuccessfulSyncMutatiedatum: string;
    /**
     * 
     * @type {number}
     * @memberof SUREbusinessCCSPublisherFeaturesRelatieRelatiePublishJobVerwerkMutatiesResult
     */
    toegevoegd: number;
    /**
     * 
     * @type {number}
     * @memberof SUREbusinessCCSPublisherFeaturesRelatieRelatiePublishJobVerwerkMutatiesResult
     */
    gewijzigd: number;
    /**
     * 
     * @type {number}
     * @memberof SUREbusinessCCSPublisherFeaturesRelatieRelatiePublishJobVerwerkMutatiesResult
     */
    verwijderd: number;
    /**
     * 
     * @type {number}
     * @memberof SUREbusinessCCSPublisherFeaturesRelatieRelatiePublishJobVerwerkMutatiesResult
     */
    gefaald: number;
}
/**
 * 
 * @export
 * @interface SUREbusinessCCSReadingOdbcGetTabellenTabelDto
 */
export interface SUREbusinessCCSReadingOdbcGetTabellenTabelDto {
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessCCSReadingOdbcGetTabellenTabelDto
     */
    naam: string;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessCCSReadingOdbcGetTabellenTabelDto
     */
    omschrijving: string;
}
/**
 * 
 * @export
 * @interface SUREbusinessManagementAanvragenInstellingenAanvraagInstellingenDto
 */
export interface SUREbusinessManagementAanvragenInstellingenAanvraagInstellingenDto {
    /**
     * 
     * @type {boolean}
     * @memberof SUREbusinessManagementAanvragenInstellingenAanvraagInstellingenDto
     */
    vpiBerekeningenBlokkeren: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SUREbusinessManagementAanvragenInstellingenAanvraagInstellingenDto
     */
    alleStpAanvragenBlokkeren: boolean;
    /**
     * 
     * @type {number}
     * @memberof SUREbusinessManagementAanvragenInstellingenAanvraagInstellingenDto
     */
    grensMaxVerledenIngangsdatum: number;
    /**
     * 
     * @type {number}
     * @memberof SUREbusinessManagementAanvragenInstellingenAanvraagInstellingenDto
     */
    grensMaxVerledenIngangsdatumIntern: number;
    /**
     * 
     * @type {number}
     * @memberof SUREbusinessManagementAanvragenInstellingenAanvraagInstellingenDto
     */
    grensMaxVerledenMutatie: number;
    /**
     * 
     * @type {number}
     * @memberof SUREbusinessManagementAanvragenInstellingenAanvraagInstellingenDto
     */
    grensMaxVerledenMutatieIntern: number;
    /**
     * 
     * @type {number}
     * @memberof SUREbusinessManagementAanvragenInstellingenAanvraagInstellingenDto
     */
    grensMaxVerledenMutatieIngangsdatum: number;
    /**
     * 
     * @type {number}
     * @memberof SUREbusinessManagementAanvragenInstellingenAanvraagInstellingenDto
     */
    grensMaxVerledenMutatieIngangsdatumIntern: number;
    /**
     * 
     * @type {number}
     * @memberof SUREbusinessManagementAanvragenInstellingenAanvraagInstellingenDto
     */
    offerteAantaldagenGeldig: number;
    /**
     * 
     * @type {SUREbusinessManagementDomainDomainBetalingsTermijnRegel}
     * @memberof SUREbusinessManagementAanvragenInstellingenAanvraagInstellingenDto
     */
    betalingstermijnRegel1mnd: SUREbusinessManagementDomainDomainBetalingsTermijnRegel;
    /**
     * 
     * @type {SUREbusinessManagementDomainDomainBetalingsTermijnRegel}
     * @memberof SUREbusinessManagementAanvragenInstellingenAanvraagInstellingenDto
     */
    betalingstermijnRegel3mnd: SUREbusinessManagementDomainDomainBetalingsTermijnRegel;
    /**
     * 
     * @type {SUREbusinessManagementDomainDomainBetalingsTermijnRegel}
     * @memberof SUREbusinessManagementAanvragenInstellingenAanvraagInstellingenDto
     */
    betalingstermijnRegel12mnd: SUREbusinessManagementDomainDomainBetalingsTermijnRegel;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum SUREbusinessManagementDomainDomainBetalingsTermijnRegel {
    Altijd = 'altijd',
    Nietmogelijk = 'nietmogelijk',
    VanafBrprpr2000 = 'vanaf_brprpr2000'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum SUREbusinessManagementDomainDomainEnumsMeldingType {
    Onderhoud = 'Onderhoud',
    Inlogpagina = 'Inlogpagina'
}

/**
 * 
 * @export
 * @interface SUREbusinessManagementKvkAdresDto
 */
export interface SUREbusinessManagementKvkAdresDto {
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementKvkAdresDto
     */
    straat: string;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementKvkAdresDto
     */
    postcode: string;
    /**
     * 
     * @type {number}
     * @memberof SUREbusinessManagementKvkAdresDto
     */
    huisnummer: number;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementKvkAdresDto
     */
    huisnummerToevoeging?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementKvkAdresDto
     */
    plaats: string;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementKvkAdresDto
     */
    land: string;
}
/**
 * 
 * @export
 * @interface SUREbusinessManagementKvkCodeOmschrijvingDto
 */
export interface SUREbusinessManagementKvkCodeOmschrijvingDto {
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementKvkCodeOmschrijvingDto
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementKvkCodeOmschrijvingDto
     */
    omschrijving: string;
}
/**
 * 
 * @export
 * @interface SUREbusinessManagementKvkKvkBedrijfDto
 */
export interface SUREbusinessManagementKvkKvkBedrijfDto {
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementKvkKvkBedrijfDto
     */
    kvkNummer: string;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementKvkKvkBedrijfDto
     */
    naam: string;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementKvkKvkBedrijfDto
     */
    straat: string;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementKvkKvkBedrijfDto
     */
    plaats: string;
}
/**
 * 
 * @export
 * @interface SUREbusinessManagementKvkKvkBedrijfsProfielDto
 */
export interface SUREbusinessManagementKvkKvkBedrijfsProfielDto {
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementKvkKvkBedrijfsProfielDto
     */
    kvkNummer: string;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementKvkKvkBedrijfsProfielDto
     */
    vestigingsNummer?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementKvkKvkBedrijfsProfielDto
     */
    naam: string;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementKvkKvkBedrijfsProfielDto
     */
    oprichtingsdatum?: string | null;
    /**
     * 
     * @type {SUREbusinessManagementKvkCodeOmschrijvingDto}
     * @memberof SUREbusinessManagementKvkKvkBedrijfsProfielDto
     */
    sbi: SUREbusinessManagementKvkCodeOmschrijvingDto;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementKvkKvkBedrijfsProfielDto
     */
    branche?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementKvkKvkBedrijfsProfielDto
     */
    sector?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementKvkKvkBedrijfsProfielDto
     */
    bedrijfstype?: string | null;
    /**
     * 
     * @type {SUREbusinessManagementKvkCodeOmschrijvingDto}
     * @memberof SUREbusinessManagementKvkKvkBedrijfsProfielDto
     */
    rechtsvorm: SUREbusinessManagementKvkCodeOmschrijvingDto;
    /**
     * 
     * @type {SUREbusinessManagementKvkAdresDto}
     * @memberof SUREbusinessManagementKvkKvkBedrijfsProfielDto
     */
    adres: SUREbusinessManagementKvkAdresDto;
    /**
     * 
     * @type {SUREbusinessManagementKvkAdresDto}
     * @memberof SUREbusinessManagementKvkKvkBedrijfsProfielDto
     */
    postAdres: SUREbusinessManagementKvkAdresDto;
}
/**
 * 
 * @export
 * @interface SUREbusinessManagementLandenGetLandenLandDto
 */
export interface SUREbusinessManagementLandenGetLandenLandDto {
    /**
     * 
     * @type {number}
     * @memberof SUREbusinessManagementLandenGetLandenLandDto
     */
    id?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementLandenGetLandenLandDto
     */
    code2: string;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementLandenGetLandenLandDto
     */
    code3: string;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementLandenGetLandenLandDto
     */
    naam: string;
}
/**
 * 
 * @export
 * @interface SUREbusinessManagementOfferteOfferteAanvraagMogelijkMeldingCompareDto
 */
export interface SUREbusinessManagementOfferteOfferteAanvraagMogelijkMeldingCompareDto {
    /**
     * 
     * @type {number}
     * @memberof SUREbusinessManagementOfferteOfferteAanvraagMogelijkMeldingCompareDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementOfferteOfferteAanvraagMogelijkMeldingCompareDto
     */
    offertenummer?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SUREbusinessManagementOfferteOfferteAanvraagMogelijkMeldingCompareDto
     */
    offerteproductid: number;
    /**
     * 
     * @type {number}
     * @memberof SUREbusinessManagementOfferteOfferteAanvraagMogelijkMeldingCompareDto
     */
    meldingid?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SUREbusinessManagementOfferteOfferteAanvraagMogelijkMeldingCompareDto
     */
    productvraagid?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementOfferteOfferteAanvraagMogelijkMeldingCompareDto
     */
    meldingsoort?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementOfferteOfferteAanvraagMogelijkMeldingCompareDto
     */
    melding?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementOfferteOfferteAanvraagMogelijkMeldingCompareDto
     */
    meldingIntern?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SUREbusinessManagementOfferteOfferteAanvraagMogelijkMeldingCompareDto
     */
    geheimvoortp?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof SUREbusinessManagementOfferteOfferteAanvraagMogelijkMeldingCompareDto
     */
    acceptatieregelid?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof SUREbusinessManagementOfferteOfferteAanvraagMogelijkMeldingCompareDto
     */
    aanvraagstatusmogelijkAkkoord?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof SUREbusinessManagementOfferteOfferteAanvraagMogelijkMeldingCompareDto
     */
    aanvraagstatusmogelijkAkkoordWacht?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementOfferteOfferteAanvraagMogelijkMeldingCompareDto
     */
    bijzonderheden?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementOfferteOfferteAanvraagMogelijkMeldingCompareDto
     */
    dateCreated?: string | null;
}
/**
 * 
 * @export
 * @interface SUREbusinessManagementOfferteOfferteBlokCompareDto
 */
export interface SUREbusinessManagementOfferteOfferteBlokCompareDto {
    /**
     * 
     * @type {number}
     * @memberof SUREbusinessManagementOfferteOfferteBlokCompareDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof SUREbusinessManagementOfferteOfferteBlokCompareDto
     */
    offerteblokid?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementOfferteOfferteBlokCompareDto
     */
    offertenummer?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementOfferteOfferteBlokCompareDto
     */
    sectie?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementOfferteOfferteBlokCompareDto
     */
    tekst?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SUREbusinessManagementOfferteOfferteBlokCompareDto
     */
    volgorde?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof SUREbusinessManagementOfferteOfferteBlokCompareDto
     */
    geselecteerd?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof SUREbusinessManagementOfferteOfferteBlokCompareDto
     */
    verwijderd?: boolean | null;
}
/**
 * 
 * @export
 * @interface SUREbusinessManagementOfferteOfferteCompareDetailDto
 */
export interface SUREbusinessManagementOfferteOfferteCompareDetailDto {
    /**
     * 
     * @type {number}
     * @memberof SUREbusinessManagementOfferteOfferteCompareDetailDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementOfferteOfferteCompareDetailDto
     */
    offertenummer?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SUREbusinessManagementOfferteOfferteCompareDetailDto
     */
    offerteproductid?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementOfferteOfferteCompareDetailDto
     */
    offerteproductmaatschappijid?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementOfferteOfferteCompareDetailDto
     */
    soort: string;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementOfferteOfferteCompareDetailDto
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementOfferteOfferteCompareDetailDto
     */
    waarde?: string | null;
}
/**
 * 
 * @export
 * @interface SUREbusinessManagementOfferteOfferteCompareDto
 */
export interface SUREbusinessManagementOfferteOfferteCompareDto {
    /**
     * 
     * @type {number}
     * @memberof SUREbusinessManagementOfferteOfferteCompareDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementOfferteOfferteCompareDto
     */
    offertenummer: string;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementOfferteOfferteCompareDto
     */
    token: string;
    /**
     * 
     * @type {number}
     * @memberof SUREbusinessManagementOfferteOfferteCompareDto
     */
    tussenpersoonnr: number;
    /**
     * 
     * @type {number}
     * @memberof SUREbusinessManagementOfferteOfferteCompareDto
     */
    accepterendeTp?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SUREbusinessManagementOfferteOfferteCompareDto
     */
    gebruikerid: number;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementOfferteOfferteCompareDto
     */
    datumaanmaak: string;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementOfferteOfferteCompareDto
     */
    datumgewijzigd?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementOfferteOfferteCompareDto
     */
    datumindexering?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SUREbusinessManagementOfferteOfferteCompareDto
     */
    relatienummer: number;
    /**
     * 
     * @type {number}
     * @memberof SUREbusinessManagementOfferteOfferteCompareDto
     */
    contactpersoonid?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof SUREbusinessManagementOfferteOfferteCompareDto
     */
    betreftwagenpark?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof SUREbusinessManagementOfferteOfferteCompareDto
     */
    betreftnaverrekening?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementOfferteOfferteCompareDto
     */
    naverrekeningIdentifier?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementOfferteOfferteCompareDto
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementOfferteOfferteCompareDto
     */
    statusWagenpark?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SUREbusinessManagementOfferteOfferteCompareDto
     */
    betreftcollectiviteit?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof SUREbusinessManagementOfferteOfferteCompareDto
     */
    gearchiveerd: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SUREbusinessManagementOfferteOfferteCompareDto
     */
    deepArchive: boolean;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementOfferteOfferteCompareDto
     */
    laatstecontroleaanvraagmogelijk?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementOfferteOfferteCompareDto
     */
    datumgecontroleerd?: string | null;
    /**
     * 
     * @type {Array<SUREbusinessManagementOfferteOfferteCompareDetailDto>}
     * @memberof SUREbusinessManagementOfferteOfferteCompareDto
     */
    details: Array<SUREbusinessManagementOfferteOfferteCompareDetailDto>;
    /**
     * 
     * @type {Array<SUREbusinessManagementOfferteOfferteProductCompareDto>}
     * @memberof SUREbusinessManagementOfferteOfferteCompareDto
     */
    producten: Array<SUREbusinessManagementOfferteOfferteProductCompareDto>;
    /**
     * 
     * @type {Array<SUREbusinessManagementOfferteOfferteBlokCompareDto>}
     * @memberof SUREbusinessManagementOfferteOfferteCompareDto
     */
    blokken: Array<SUREbusinessManagementOfferteOfferteBlokCompareDto>;
    /**
     * 
     * @type {Array<SUREbusinessManagementOfferteOfferteAanvraagMogelijkMeldingCompareDto>}
     * @memberof SUREbusinessManagementOfferteOfferteCompareDto
     */
    aanvragenMogelijkMeldingen: Array<SUREbusinessManagementOfferteOfferteAanvraagMogelijkMeldingCompareDto>;
}
/**
 * 
 * @export
 * @interface SUREbusinessManagementOfferteOfferteProductCcsCompareDto
 */
export interface SUREbusinessManagementOfferteOfferteProductCcsCompareDto {
    /**
     * 
     * @type {number}
     * @memberof SUREbusinessManagementOfferteOfferteProductCcsCompareDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementOfferteOfferteProductCcsCompareDto
     */
    offertenummer?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SUREbusinessManagementOfferteOfferteProductCcsCompareDto
     */
    offerteproductid?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementOfferteOfferteProductCcsCompareDto
     */
    polisnummer?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementOfferteOfferteProductCcsCompareDto
     */
    maatschappijid?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementOfferteOfferteProductCcsCompareDto
     */
    soortverzekering?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SUREbusinessManagementOfferteOfferteProductCcsCompareDto
     */
    productcode?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementOfferteOfferteProductCcsCompareDto
     */
    prodoms?: string | null;
}
/**
 * 
 * @export
 * @interface SUREbusinessManagementOfferteOfferteProductCompareDto
 */
export interface SUREbusinessManagementOfferteOfferteProductCompareDto {
    /**
     * 
     * @type {number}
     * @memberof SUREbusinessManagementOfferteOfferteProductCompareDto
     */
    offerteproductid: number;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementOfferteOfferteProductCompareDto
     */
    offertenummer?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementOfferteOfferteProductCompareDto
     */
    productid?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SUREbusinessManagementOfferteOfferteProductCompareDto
     */
    volgnummer?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof SUREbusinessManagementOfferteOfferteProductCompareDto
     */
    verwijderd?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementOfferteOfferteProductCompareDto
     */
    gekozenproductmaatschappijid?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SUREbusinessManagementOfferteOfferteProductCompareDto
     */
    passief?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof SUREbusinessManagementOfferteOfferteProductCompareDto
     */
    betreftmaatwerk?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementOfferteOfferteProductCompareDto
     */
    soortofferteproduct?: string | null;
    /**
     * 
     * @type {Array<SUREbusinessManagementOfferteOfferteCompareDetailDto>}
     * @memberof SUREbusinessManagementOfferteOfferteProductCompareDto
     */
    details: Array<SUREbusinessManagementOfferteOfferteCompareDetailDto>;
    /**
     * 
     * @type {Array<SUREbusinessManagementOfferteOfferteProductMaatwerkCompareDto>}
     * @memberof SUREbusinessManagementOfferteOfferteProductCompareDto
     */
    maatwerk: Array<SUREbusinessManagementOfferteOfferteProductMaatwerkCompareDto>;
    /**
     * 
     * @type {Array<SUREbusinessManagementOfferteOfferteProductCcsCompareDto>}
     * @memberof SUREbusinessManagementOfferteOfferteProductCompareDto
     */
    ccs: Array<SUREbusinessManagementOfferteOfferteProductCcsCompareDto>;
}
/**
 * 
 * @export
 * @interface SUREbusinessManagementOfferteOfferteProductMaatwerkCompareDto
 */
export interface SUREbusinessManagementOfferteOfferteProductMaatwerkCompareDto {
    /**
     * 
     * @type {number}
     * @memberof SUREbusinessManagementOfferteOfferteProductMaatwerkCompareDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof SUREbusinessManagementOfferteOfferteProductMaatwerkCompareDto
     */
    offerteproductid: number;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementOfferteOfferteProductMaatwerkCompareDto
     */
    aanvraagdatum?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementOfferteOfferteProductMaatwerkCompareDto
     */
    datumgewijzigd?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementOfferteOfferteProductMaatwerkCompareDto
     */
    datumindexering?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementOfferteOfferteProductMaatwerkCompareDto
     */
    datumafgerond?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementOfferteOfferteProductMaatwerkCompareDto
     */
    statusid?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SUREbusinessManagementOfferteOfferteProductMaatwerkCompareDto
     */
    behandelaarinterngebruikerid?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof SUREbusinessManagementOfferteOfferteProductMaatwerkCompareDto
     */
    gearchiveerd?: boolean | null;
}
/**
 * 
 * @export
 * @interface SUREbusinessManagementOnderhoudDtosOnderhoudsmeldingDto
 */
export interface SUREbusinessManagementOnderhoudDtosOnderhoudsmeldingDto {
    /**
     * 
     * @type {number}
     * @memberof SUREbusinessManagementOnderhoudDtosOnderhoudsmeldingDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementOnderhoudDtosOnderhoudsmeldingDto
     */
    sort: string;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementOnderhoudDtosOnderhoudsmeldingDto
     */
    text: string;
}
/**
 * 
 * @export
 * @interface SUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto
 */
export interface SUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto {
    /**
     * 
     * @type {number}
     * @memberof SUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto
     */
    meldingId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto
     */
    isActief: boolean;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto
     */
    tijdEind: string;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto
     */
    tijdStart: string;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto
     */
    meldingInlogPagina: string;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto
     */
    meldingWebmodule: string;
    /**
     * 
     * @type {SUREbusinessManagementDomainDomainEnumsMeldingType}
     * @memberof SUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto
     */
    meldingType: SUREbusinessManagementDomainDomainEnumsMeldingType;
}
/**
 * 
 * @export
 * @interface SUREbusinessManagementOnderhoudDtosOnderhoudsvensterLogDto
 */
export interface SUREbusinessManagementOnderhoudDtosOnderhoudsvensterLogDto {
    /**
     * 
     * @type {number}
     * @memberof SUREbusinessManagementOnderhoudDtosOnderhoudsvensterLogDto
     */
    gebruikerId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementOnderhoudDtosOnderhoudsvensterLogDto
     */
    gebruiker: string;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementOnderhoudDtosOnderhoudsvensterLogDto
     */
    logTekst: string;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementOnderhoudDtosOnderhoudsvensterLogDto
     */
    activiteit: string;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementOnderhoudDtosOnderhoudsvensterLogDto
     */
    tijdstip: string;
}
/**
 * 
 * @export
 * @interface SUREbusinessManagementReportsColumnDefinition
 */
export interface SUREbusinessManagementReportsColumnDefinition {
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementReportsColumnDefinition
     */
    name: string;
    /**
     * 
     * @type {SUREbusinessManagementReportsFormattingOption}
     * @memberof SUREbusinessManagementReportsColumnDefinition
     */
    formattingOption: SUREbusinessManagementReportsFormattingOption;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementReportsColumnDefinition
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementReportsColumnDefinition
     */
    url?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum SUREbusinessManagementReportsFormattingOption {
    Date = 'Date',
    DateTime = 'DateTime'
}

/**
 * 
 * @export
 * @interface SUREbusinessManagementReportsGetAvailableReportsDto
 */
export interface SUREbusinessManagementReportsGetAvailableReportsDto {
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementReportsGetAvailableReportsDto
     */
    name: string;
}
/**
 * 
 * @export
 * @interface SUREbusinessManagementReportsTableDefintion1OfSystemObject
 */
export interface SUREbusinessManagementReportsTableDefintion1OfSystemObject {
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementReportsTableDefintion1OfSystemObject
     */
    generatedAt: string;
    /**
     * 
     * @type {Array<SUREbusinessManagementReportsColumnDefinition>}
     * @memberof SUREbusinessManagementReportsTableDefintion1OfSystemObject
     */
    columns: Array<SUREbusinessManagementReportsColumnDefinition>;
    /**
     * 
     * @type {Array<any>}
     * @memberof SUREbusinessManagementReportsTableDefintion1OfSystemObject
     */
    rows: Array<any>;
}
/**
 * 
 * @export
 * @interface SUREbusinessManagementSearchSearchDto
 */
export interface SUREbusinessManagementSearchSearchDto {
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementSearchSearchDto
     */
    identifier: string;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementSearchSearchDto
     */
    omschrijving: string;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementSearchSearchDto
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementSearchSearchDto
     */
    status: string;
    /**
     * 
     * @type {SUREbusinessManagementSearchSearchType}
     * @memberof SUREbusinessManagementSearchSearchDto
     */
    type: SUREbusinessManagementSearchSearchType;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum SUREbusinessManagementSearchSearchType {
    Relatie = 'Relatie',
    Offerte = 'Offerte',
    Aanvraag = 'Aanvraag',
    Mutatie = 'Mutatie',
    Schade = 'Schade',
    Royement = 'Royement',
    Overvoer = 'Overvoer',
    Schademelding = 'Schademelding',
    Maatwerk = 'Maatwerk',
    Polis = 'Polis'
}

/**
 * 
 * @export
 * @interface SUREbusinessManagementToolsQuerytoolDtoColumn
 */
export interface SUREbusinessManagementToolsQuerytoolDtoColumn {
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementToolsQuerytoolDtoColumn
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementToolsQuerytoolDtoColumn
     */
    datatype: string;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementToolsQuerytoolDtoColumn
     */
    format: string;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementToolsQuerytoolDtoColumn
     */
    nullFlag: string;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementToolsQuerytoolDtoColumn
     */
    initial: string;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementToolsQuerytoolDtoColumn
     */
    label: string;
}
/**
 * 
 * @export
 * @interface SUREbusinessManagementToolsQuerytoolDtoOdbcQueryDto
 */
export interface SUREbusinessManagementToolsQuerytoolDtoOdbcQueryDto {
    /**
     * 
     * @type {number}
     * @memberof SUREbusinessManagementToolsQuerytoolDtoOdbcQueryDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementToolsQuerytoolDtoOdbcQueryDto
     */
    voornaam: string;
    /**
     * 
     * @type {SUREbusinessManagementToolsQuerytoolSelectedDatabaseEnum}
     * @memberof SUREbusinessManagementToolsQuerytoolDtoOdbcQueryDto
     */
    bronDatabase: SUREbusinessManagementToolsQuerytoolSelectedDatabaseEnum;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementToolsQuerytoolDtoOdbcQueryDto
     */
    omschrijving: string;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementToolsQuerytoolDtoOdbcQueryDto
     */
    query: string;
}
/**
 * 
 * @export
 * @interface SUREbusinessManagementToolsQuerytoolDtoQueryTable
 */
export interface SUREbusinessManagementToolsQuerytoolDtoQueryTable {
    /**
     * 
     * @type {number}
     * @memberof SUREbusinessManagementToolsQuerytoolDtoQueryTable
     */
    id: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof SUREbusinessManagementToolsQuerytoolDtoQueryTable
     */
    headers: Array<string>;
    /**
     * 
     * @type {Array<Array<string>>}
     * @memberof SUREbusinessManagementToolsQuerytoolDtoQueryTable
     */
    rows: Array<Array<string>>;
    /**
     * 
     * @type {number}
     * @memberof SUREbusinessManagementToolsQuerytoolDtoQueryTable
     */
    executionTimeMs: number;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementToolsQuerytoolDtoQueryTable
     */
    exception?: string | null;
}
/**
 * 
 * @export
 * @interface SUREbusinessManagementToolsQuerytoolDtoTableIndex
 */
export interface SUREbusinessManagementToolsQuerytoolDtoTableIndex {
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementToolsQuerytoolDtoTableIndex
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof SUREbusinessManagementToolsQuerytoolDtoTableIndex
     */
    sequence: number;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementToolsQuerytoolDtoTableIndex
     */
    columnName: string;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementToolsQuerytoolDtoTableIndex
     */
    type: string;
}
/**
 * 
 * @export
 * @interface SUREbusinessManagementToolsQuerytoolDtoTableReference
 */
export interface SUREbusinessManagementToolsQuerytoolDtoTableReference {
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementToolsQuerytoolDtoTableReference
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementToolsQuerytoolDtoTableReference
     */
    errorMessage: string;
    /**
     * 
     * @type {Array<SUREbusinessManagementToolsQuerytoolDtoColumn>}
     * @memberof SUREbusinessManagementToolsQuerytoolDtoTableReference
     */
    columns: Array<SUREbusinessManagementToolsQuerytoolDtoColumn>;
    /**
     * 
     * @type {Array<SUREbusinessManagementToolsQuerytoolDtoTableIndex>}
     * @memberof SUREbusinessManagementToolsQuerytoolDtoTableReference
     */
    indexes: Array<SUREbusinessManagementToolsQuerytoolDtoTableIndex>;
    /**
     * 
     * @type {Array<SUREbusinessManagementToolsQuerytoolDtoTableRelation>}
     * @memberof SUREbusinessManagementToolsQuerytoolDtoTableReference
     */
    tableRelations: Array<SUREbusinessManagementToolsQuerytoolDtoTableRelation>;
}
/**
 * 
 * @export
 * @interface SUREbusinessManagementToolsQuerytoolDtoTableRelation
 */
export interface SUREbusinessManagementToolsQuerytoolDtoTableRelation {
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementToolsQuerytoolDtoTableRelation
     */
    linkedTable: string;
    /**
     * 
     * @type {string}
     * @memberof SUREbusinessManagementToolsQuerytoolDtoTableRelation
     */
    linkedOnIndex: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum SUREbusinessManagementToolsQuerytoolSelectedDatabaseEnum {
    Aanvragen = 'Aanvragen',
    Assur = 'Assur'
}

/**
 * 
 * @export
 * @interface SystemCollectionsGenericKeyValuePair2OfSystemStringAndSystemString
 */
export interface SystemCollectionsGenericKeyValuePair2OfSystemStringAndSystemString {
    /**
     * 
     * @type {string}
     * @memberof SystemCollectionsGenericKeyValuePair2OfSystemStringAndSystemString
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof SystemCollectionsGenericKeyValuePair2OfSystemStringAndSystemString
     */
    value: string;
}
/**
 * 
 * @export
 * @interface VragenVoertuigControleerMeldcodeVoertuigDto
 */
export interface VragenVoertuigControleerMeldcodeVoertuigDto {
    /**
     * 
     * @type {string}
     * @memberof VragenVoertuigControleerMeldcodeVoertuigDto
     */
    kenteken: string;
    /**
     * 
     * @type {string}
     * @memberof VragenVoertuigControleerMeldcodeVoertuigDto
     */
    meldcode: string;
}
/**
 * 
 * @export
 * @interface VragenVoertuigGetVoertuigDataByKentekenVoertuigDto
 */
export interface VragenVoertuigGetVoertuigDataByKentekenVoertuigDto {
    /**
     * 
     * @type {string}
     * @memberof VragenVoertuigGetVoertuigDataByKentekenVoertuigDto
     */
    merk: string;
    /**
     * 
     * @type {string}
     * @memberof VragenVoertuigGetVoertuigDataByKentekenVoertuigDto
     */
    model?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VragenVoertuigGetVoertuigDataByKentekenVoertuigDto
     */
    typeVoertuig?: string | null;
    /**
     * 
     * @type {number}
     * @memberof VragenVoertuigGetVoertuigDataByKentekenVoertuigDto
     */
    bouwjaar?: number | null;
    /**
     * 
     * @type {number}
     * @memberof VragenVoertuigGetVoertuigDataByKentekenVoertuigDto
     */
    bouwmaand?: number | null;
    /**
     * 
     * @type {number}
     * @memberof VragenVoertuigGetVoertuigDataByKentekenVoertuigDto
     */
    bouwdag?: number | null;
    /**
     * 
     * @type {number}
     * @memberof VragenVoertuigGetVoertuigDataByKentekenVoertuigDto
     */
    gewicht: number;
    /**
     * 
     * @type {number}
     * @memberof VragenVoertuigGetVoertuigDataByKentekenVoertuigDto
     */
    laadvermogen: number;
    /**
     * 
     * @type {number}
     * @memberof VragenVoertuigGetVoertuigDataByKentekenVoertuigDto
     */
    cilinderinhoud: number;
    /**
     * 
     * @type {number}
     * @memberof VragenVoertuigGetVoertuigDataByKentekenVoertuigDto
     */
    vermogen: number;
    /**
     * 
     * @type {string}
     * @memberof VragenVoertuigGetVoertuigDataByKentekenVoertuigDto
     */
    soortVoertuig: string;
    /**
     * 
     * @type {string}
     * @memberof VragenVoertuigGetVoertuigDataByKentekenVoertuigDto
     */
    afdVoertuigcode: string;
    /**
     * 
     * @type {string}
     * @memberof VragenVoertuigGetVoertuigDataByKentekenVoertuigDto
     */
    voertuigomschrijving: string;
    /**
     * 
     * @type {string}
     * @memberof VragenVoertuigGetVoertuigDataByKentekenVoertuigDto
     */
    carrosserie: string;
    /**
     * 
     * @type {string}
     * @memberof VragenVoertuigGetVoertuigDataByKentekenVoertuigDto
     */
    brandstof: string;
    /**
     * 
     * @type {string}
     * @memberof VragenVoertuigGetVoertuigDataByKentekenVoertuigDto
     */
    brandstof2: string;
    /**
     * 
     * @type {number}
     * @memberof VragenVoertuigGetVoertuigDataByKentekenVoertuigDto
     */
    cataloguswaarde?: number | null;
    /**
     * 
     * @type {number}
     * @memberof VragenVoertuigGetVoertuigDataByKentekenVoertuigDto
     */
    cataloguswaardeExBpm?: number | null;
    /**
     * 
     * @type {number}
     * @memberof VragenVoertuigGetVoertuigDataByKentekenVoertuigDto
     */
    bpm: number;
    /**
     * 
     * @type {string}
     * @memberof VragenVoertuigGetVoertuigDataByKentekenVoertuigDto
     */
    transmissie: string;
    /**
     * 
     * @type {string}
     * @memberof VragenVoertuigGetVoertuigDataByKentekenVoertuigDto
     */
    aandrijving: string;
    /**
     * 
     * @type {number}
     * @memberof VragenVoertuigGetVoertuigDataByKentekenVoertuigDto
     */
    acceleratie: number;
    /**
     * 
     * @type {string}
     * @memberof VragenVoertuigGetVoertuigDataByKentekenVoertuigDto
     */
    huidigeSoortEigenaar: string;
    /**
     * 
     * @type {number}
     * @memberof VragenVoertuigGetVoertuigDataByKentekenVoertuigDto
     */
    aantalDeuren: number;
    /**
     * 
     * @type {string}
     * @memberof VragenVoertuigGetVoertuigDataByKentekenVoertuigDto
     */
    kleur: string;
    /**
     * 
     * @type {number}
     * @memberof VragenVoertuigGetVoertuigDataByKentekenVoertuigDto
     */
    gewichtOplegger: number;
    /**
     * 
     * @type {string}
     * @memberof VragenVoertuigGetVoertuigDataByKentekenVoertuigDto
     */
    datumLaatsteTenaamstelling?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VragenVoertuigGetVoertuigDataByKentekenVoertuigDto
     */
    datumEersteAfgifte?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VragenVoertuigGetVoertuigDataByKentekenVoertuigDto
     */
    rdwinrichting: string;
    /**
     * 
     * @type {number}
     * @memberof VragenVoertuigGetVoertuigDataByKentekenVoertuigDto
     */
    maxMassa: number;
    /**
     * 
     * @type {string}
     * @memberof VragenVoertuigGetVoertuigDataByKentekenVoertuigDto
     */
    carrosserieNieuw: string;
    /**
     * 
     * @type {string}
     * @memberof VragenVoertuigGetVoertuigDataByKentekenVoertuigDto
     */
    transmissieNieuw: string;
    /**
     * 
     * @type {boolean}
     * @memberof VragenVoertuigGetVoertuigDataByKentekenVoertuigDto
     */
    isTaxi?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof VragenVoertuigGetVoertuigDataByKentekenVoertuigDto
     */
    dagwaarde?: number | null;
    /**
     * 
     * @type {number}
     * @memberof VragenVoertuigGetVoertuigDataByKentekenVoertuigDto
     */
    cataloguswaardeExBtw?: number | null;
    /**
     * 
     * @type {number}
     * @memberof VragenVoertuigGetVoertuigDataByKentekenVoertuigDto
     */
    dagwaardeExBtw?: number | null;
    /**
     * 
     * @type {string}
     * @memberof VragenVoertuigGetVoertuigDataByKentekenVoertuigDto
     */
    bouwdatum?: string | null;
    /**
     * 
     * @type {number}
     * @memberof VragenVoertuigGetVoertuigDataByKentekenVoertuigDto
     */
    middenasaanhangwagenGeremd?: number | null;
    /**
     * 
     * @type {number}
     * @memberof VragenVoertuigGetVoertuigDataByKentekenVoertuigDto
     */
    autonomeAanhangwagenGeremd?: number | null;
    /**
     * 
     * @type {string}
     * @memberof VragenVoertuigGetVoertuigDataByKentekenVoertuigDto
     */
    specialeDoeleinden: string;
}

/**
 * AanvragenSettingsApiApi - axios parameter creator
 * @export
 */
export const AanvragenSettingsApiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Inregeling.InregelingInzien</li></ul>
         * @summary Haalt alle instellingen op.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aanvragenSettingsAll: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/aanvragen-settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Inregeling.InregelingAanpassen</li></ul>
         * @summary Werkt een bestaande instelling bij.
         * @param {SUREbusinessManagementAanvragenInstellingenAanvraagInstellingenDto} [sUREbusinessManagementAanvragenInstellingenAanvraagInstellingenDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aanvragenSettingsPut: async (sUREbusinessManagementAanvragenInstellingenAanvraagInstellingenDto?: SUREbusinessManagementAanvragenInstellingenAanvraagInstellingenDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/aanvragen-settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sUREbusinessManagementAanvragenInstellingenAanvraagInstellingenDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AanvragenSettingsApiApi - functional programming interface
 * @export
 */
export const AanvragenSettingsApiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AanvragenSettingsApiApiAxiosParamCreator(configuration)
    return {
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Inregeling.InregelingInzien</li></ul>
         * @summary Haalt alle instellingen op.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aanvragenSettingsAll(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result1OfSUREbusinessManagementAanvragenInstellingenAanvraagInstellingenDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aanvragenSettingsAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Inregeling.InregelingAanpassen</li></ul>
         * @summary Werkt een bestaande instelling bij.
         * @param {SUREbusinessManagementAanvragenInstellingenAanvraagInstellingenDto} [sUREbusinessManagementAanvragenInstellingenAanvraagInstellingenDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aanvragenSettingsPut(sUREbusinessManagementAanvragenInstellingenAanvraagInstellingenDto?: SUREbusinessManagementAanvragenInstellingenAanvraagInstellingenDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aanvragenSettingsPut(sUREbusinessManagementAanvragenInstellingenAanvraagInstellingenDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AanvragenSettingsApiApi - factory interface
 * @export
 */
export const AanvragenSettingsApiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AanvragenSettingsApiApiFp(configuration)
    return {
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Inregeling.InregelingInzien</li></ul>
         * @summary Haalt alle instellingen op.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aanvragenSettingsAll(options?: any): AxiosPromise<Result1OfSUREbusinessManagementAanvragenInstellingenAanvraagInstellingenDto> {
            return localVarFp.aanvragenSettingsAll(options).then((request) => request(axios, basePath));
        },
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Inregeling.InregelingAanpassen</li></ul>
         * @summary Werkt een bestaande instelling bij.
         * @param {SUREbusinessManagementAanvragenInstellingenAanvraagInstellingenDto} [sUREbusinessManagementAanvragenInstellingenAanvraagInstellingenDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aanvragenSettingsPut(sUREbusinessManagementAanvragenInstellingenAanvraagInstellingenDto?: SUREbusinessManagementAanvragenInstellingenAanvraagInstellingenDto, options?: any): AxiosPromise<void> {
            return localVarFp.aanvragenSettingsPut(sUREbusinessManagementAanvragenInstellingenAanvraagInstellingenDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AanvragenSettingsApiApi - interface
 * @export
 * @interface AanvragenSettingsApiApi
 */
export interface AanvragenSettingsApiApiInterface {
    /**
     *  <br /> Required permissions:<br /> <ul> <li>Permission: Inregeling.InregelingInzien</li></ul>
     * @summary Haalt alle instellingen op.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AanvragenSettingsApiApiInterface
     */
    aanvragenSettingsAll(options?: any): AxiosPromise<Result1OfSUREbusinessManagementAanvragenInstellingenAanvraagInstellingenDto>;

    /**
     *  <br /> Required permissions:<br /> <ul> <li>Permission: Inregeling.InregelingAanpassen</li></ul>
     * @summary Werkt een bestaande instelling bij.
     * @param {SUREbusinessManagementAanvragenInstellingenAanvraagInstellingenDto} [sUREbusinessManagementAanvragenInstellingenAanvraagInstellingenDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AanvragenSettingsApiApiInterface
     */
    aanvragenSettingsPut(sUREbusinessManagementAanvragenInstellingenAanvraagInstellingenDto?: SUREbusinessManagementAanvragenInstellingenAanvraagInstellingenDto, options?: any): AxiosPromise<void>;

}

/**
 * AanvragenSettingsApiApi - object-oriented interface
 * @export
 * @class AanvragenSettingsApiApi
 * @extends {BaseAPI}
 */
export class AanvragenSettingsApiApi extends BaseAPI implements AanvragenSettingsApiApiInterface {
    /**
     *  <br /> Required permissions:<br /> <ul> <li>Permission: Inregeling.InregelingInzien</li></ul>
     * @summary Haalt alle instellingen op.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AanvragenSettingsApiApi
     */
    public aanvragenSettingsAll(options?: any) {
        return AanvragenSettingsApiApiFp(this.configuration).aanvragenSettingsAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <br /> Required permissions:<br /> <ul> <li>Permission: Inregeling.InregelingAanpassen</li></ul>
     * @summary Werkt een bestaande instelling bij.
     * @param {SUREbusinessManagementAanvragenInstellingenAanvraagInstellingenDto} [sUREbusinessManagementAanvragenInstellingenAanvraagInstellingenDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AanvragenSettingsApiApi
     */
    public aanvragenSettingsPut(sUREbusinessManagementAanvragenInstellingenAanvraagInstellingenDto?: SUREbusinessManagementAanvragenInstellingenAanvraagInstellingenDto, options?: any) {
        return AanvragenSettingsApiApiFp(this.configuration).aanvragenSettingsPut(sUREbusinessManagementAanvragenInstellingenAanvraagInstellingenDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuditLogApiApi - axios parameter creator
 * @export
 */
export const AuditLogApiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Inregeling.InregelingInzien</li></ul>
         * @summary Geeft alle productmaatschappijen terug voor een specifiek product
         * @param {string} productId 
         * @param {string} [productmaatschappijId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auditLogGet: async (productId: string, productmaatschappijId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('auditLogGet', 'productId', productId)
            const localVarPath = `/api/v1/auditlogs/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (productmaatschappijId !== undefined) {
                localVarQueryParameter['productmaatschappijId'] = productmaatschappijId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuditLogApiApi - functional programming interface
 * @export
 */
export const AuditLogApiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuditLogApiApiAxiosParamCreator(configuration)
    return {
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Inregeling.InregelingInzien</li></ul>
         * @summary Geeft alle productmaatschappijen terug voor een specifiek product
         * @param {string} productId 
         * @param {string} [productmaatschappijId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async auditLogGet(productId: string, productmaatschappijId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CollectionResultOfSUREbusinessAuditingEntitiesAuditBeheerboxProductLog>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.auditLogGet(productId, productmaatschappijId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuditLogApiApi - factory interface
 * @export
 */
export const AuditLogApiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuditLogApiApiFp(configuration)
    return {
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Inregeling.InregelingInzien</li></ul>
         * @summary Geeft alle productmaatschappijen terug voor een specifiek product
         * @param {string} productId 
         * @param {string} [productmaatschappijId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auditLogGet(productId: string, productmaatschappijId?: string, options?: any): AxiosPromise<CollectionResultOfSUREbusinessAuditingEntitiesAuditBeheerboxProductLog> {
            return localVarFp.auditLogGet(productId, productmaatschappijId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuditLogApiApi - interface
 * @export
 * @interface AuditLogApiApi
 */
export interface AuditLogApiApiInterface {
    /**
     *  <br /> Required permissions:<br /> <ul> <li>Permission: Inregeling.InregelingInzien</li></ul>
     * @summary Geeft alle productmaatschappijen terug voor een specifiek product
     * @param {string} productId 
     * @param {string} [productmaatschappijId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditLogApiApiInterface
     */
    auditLogGet(productId: string, productmaatschappijId?: string, options?: any): AxiosPromise<CollectionResultOfSUREbusinessAuditingEntitiesAuditBeheerboxProductLog>;

}

/**
 * AuditLogApiApi - object-oriented interface
 * @export
 * @class AuditLogApiApi
 * @extends {BaseAPI}
 */
export class AuditLogApiApi extends BaseAPI implements AuditLogApiApiInterface {
    /**
     *  <br /> Required permissions:<br /> <ul> <li>Permission: Inregeling.InregelingInzien</li></ul>
     * @summary Geeft alle productmaatschappijen terug voor een specifiek product
     * @param {string} productId 
     * @param {string} [productmaatschappijId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditLogApiApi
     */
    public auditLogGet(productId: string, productmaatschappijId?: string, options?: any) {
        return AuditLogApiApiFp(this.configuration).auditLogGet(productId, productmaatschappijId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BrandingApi - axios parameter creator
 * @export
 */
export const BrandingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [adviseurnummer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandingGet: async (adviseurnummer?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/branding`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (adviseurnummer !== undefined) {
                localVarQueryParameter['adviseurnummer'] = adviseurnummer;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Inregeling.InregelingAanpassen</li></ul>
         * @param {number} [adviseurnunmmer] 
         * @param {{ [key: string]: string; }} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandingPost: async (adviseurnunmmer?: number, requestBody?: { [key: string]: string; }, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/branding`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (adviseurnunmmer !== undefined) {
                localVarQueryParameter['adviseurnunmmer'] = adviseurnunmmer;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BrandingApi - functional programming interface
 * @export
 */
export const BrandingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BrandingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [adviseurnummer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brandingGet(adviseurnummer?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result1OfCrmTussenPersonenGetAdviseurBrandingBrandingDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brandingGet(adviseurnummer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Inregeling.InregelingAanpassen</li></ul>
         * @param {number} [adviseurnunmmer] 
         * @param {{ [key: string]: string; }} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brandingPost(adviseurnunmmer?: number, requestBody?: { [key: string]: string; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brandingPost(adviseurnunmmer, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BrandingApi - factory interface
 * @export
 */
export const BrandingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BrandingApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [adviseurnummer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandingGet(adviseurnummer?: number, options?: any): AxiosPromise<Result1OfCrmTussenPersonenGetAdviseurBrandingBrandingDto> {
            return localVarFp.brandingGet(adviseurnummer, options).then((request) => request(axios, basePath));
        },
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Inregeling.InregelingAanpassen</li></ul>
         * @param {number} [adviseurnunmmer] 
         * @param {{ [key: string]: string; }} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandingPost(adviseurnunmmer?: number, requestBody?: { [key: string]: string; }, options?: any): AxiosPromise<void> {
            return localVarFp.brandingPost(adviseurnunmmer, requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BrandingApi - interface
 * @export
 * @interface BrandingApi
 */
export interface BrandingApiInterface {
    /**
     * 
     * @param {number} [adviseurnummer] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandingApiInterface
     */
    brandingGet(adviseurnummer?: number, options?: any): AxiosPromise<Result1OfCrmTussenPersonenGetAdviseurBrandingBrandingDto>;

    /**
     *  <br /> Required permissions:<br /> <ul> <li>Permission: Inregeling.InregelingAanpassen</li></ul>
     * @param {number} [adviseurnunmmer] 
     * @param {{ [key: string]: string; }} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandingApiInterface
     */
    brandingPost(adviseurnunmmer?: number, requestBody?: { [key: string]: string; }, options?: any): AxiosPromise<void>;

}

/**
 * BrandingApi - object-oriented interface
 * @export
 * @class BrandingApi
 * @extends {BaseAPI}
 */
export class BrandingApi extends BaseAPI implements BrandingApiInterface {
    /**
     * 
     * @param {number} [adviseurnummer] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandingApi
     */
    public brandingGet(adviseurnummer?: number, options?: any) {
        return BrandingApiFp(this.configuration).brandingGet(adviseurnummer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <br /> Required permissions:<br /> <ul> <li>Permission: Inregeling.InregelingAanpassen</li></ul>
     * @param {number} [adviseurnunmmer] 
     * @param {{ [key: string]: string; }} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandingApi
     */
    public brandingPost(adviseurnunmmer?: number, requestBody?: { [key: string]: string; }, options?: any) {
        return BrandingApiFp(this.configuration).brandingPost(adviseurnunmmer, requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CcsProlongatieApi - axios parameter creator
 * @export
 */
export const CcsProlongatieApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Inregeling.InregelingInzien</li></ul>
         * @param {boolean} [dtoAlleenVerschillen] 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ccsProlongatieConvert: async (dtoAlleenVerschillen?: boolean, file?: any, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/ccs-prolongatie`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (dtoAlleenVerschillen !== undefined) { 
                localVarFormParams.append('Dto.AlleenVerschillen', dtoAlleenVerschillen as any);
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('File', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CcsProlongatieApi - functional programming interface
 * @export
 */
export const CcsProlongatieApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CcsProlongatieApiAxiosParamCreator(configuration)
    return {
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Inregeling.InregelingInzien</li></ul>
         * @param {boolean} [dtoAlleenVerschillen] 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ccsProlongatieConvert(dtoAlleenVerschillen?: boolean, file?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ccsProlongatieConvert(dtoAlleenVerschillen, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CcsProlongatieApi - factory interface
 * @export
 */
export const CcsProlongatieApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CcsProlongatieApiFp(configuration)
    return {
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Inregeling.InregelingInzien</li></ul>
         * @param {boolean} [dtoAlleenVerschillen] 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ccsProlongatieConvert(dtoAlleenVerschillen?: boolean, file?: any, options?: any): AxiosPromise<any> {
            return localVarFp.ccsProlongatieConvert(dtoAlleenVerschillen, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CcsProlongatieApi - interface
 * @export
 * @interface CcsProlongatieApi
 */
export interface CcsProlongatieApiInterface {
    /**
     *  <br /> Required permissions:<br /> <ul> <li>Permission: Inregeling.InregelingInzien</li></ul>
     * @param {boolean} [dtoAlleenVerschillen] 
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CcsProlongatieApiInterface
     */
    ccsProlongatieConvert(dtoAlleenVerschillen?: boolean, file?: any, options?: any): AxiosPromise<any>;

}

/**
 * CcsProlongatieApi - object-oriented interface
 * @export
 * @class CcsProlongatieApi
 * @extends {BaseAPI}
 */
export class CcsProlongatieApi extends BaseAPI implements CcsProlongatieApiInterface {
    /**
     *  <br /> Required permissions:<br /> <ul> <li>Permission: Inregeling.InregelingInzien</li></ul>
     * @param {boolean} [dtoAlleenVerschillen] 
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CcsProlongatieApi
     */
    public ccsProlongatieConvert(dtoAlleenVerschillen?: boolean, file?: any, options?: any) {
        return CcsProlongatieApiFp(this.configuration).ccsProlongatieConvert(dtoAlleenVerschillen, file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CcsRelatiePublisherApi - axios parameter creator
 * @export
 */
export const CcsRelatiePublisherApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Crm.RelatieSynchroniseren</li></ul>
         * @summary Triggered de sync voor opgegegeven relaties, nu nog voornamelijk voor tests en debug doeleinde
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ccsRelatiePublisherSyncRelaties: async (requestBody?: Array<number>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/ccs-relatie/SyncRelaties`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CcsRelatiePublisherApi - functional programming interface
 * @export
 */
export const CcsRelatiePublisherApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CcsRelatiePublisherApiAxiosParamCreator(configuration)
    return {
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Crm.RelatieSynchroniseren</li></ul>
         * @summary Triggered de sync voor opgegegeven relaties, nu nog voornamelijk voor tests en debug doeleinde
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ccsRelatiePublisherSyncRelaties(requestBody?: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SUREbusinessCCSPublisherFeaturesRelatieRelatiePublishJobVerwerkMutatiesResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ccsRelatiePublisherSyncRelaties(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CcsRelatiePublisherApi - factory interface
 * @export
 */
export const CcsRelatiePublisherApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CcsRelatiePublisherApiFp(configuration)
    return {
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Crm.RelatieSynchroniseren</li></ul>
         * @summary Triggered de sync voor opgegegeven relaties, nu nog voornamelijk voor tests en debug doeleinde
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ccsRelatiePublisherSyncRelaties(requestBody?: Array<number>, options?: any): AxiosPromise<SUREbusinessCCSPublisherFeaturesRelatieRelatiePublishJobVerwerkMutatiesResult> {
            return localVarFp.ccsRelatiePublisherSyncRelaties(requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CcsRelatiePublisherApi - interface
 * @export
 * @interface CcsRelatiePublisherApi
 */
export interface CcsRelatiePublisherApiInterface {
    /**
     *  <br /> Required permissions:<br /> <ul> <li>Permission: Crm.RelatieSynchroniseren</li></ul>
     * @summary Triggered de sync voor opgegegeven relaties, nu nog voornamelijk voor tests en debug doeleinde
     * @param {Array<number>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CcsRelatiePublisherApiInterface
     */
    ccsRelatiePublisherSyncRelaties(requestBody?: Array<number>, options?: any): AxiosPromise<SUREbusinessCCSPublisherFeaturesRelatieRelatiePublishJobVerwerkMutatiesResult>;

}

/**
 * CcsRelatiePublisherApi - object-oriented interface
 * @export
 * @class CcsRelatiePublisherApi
 * @extends {BaseAPI}
 */
export class CcsRelatiePublisherApi extends BaseAPI implements CcsRelatiePublisherApiInterface {
    /**
     *  <br /> Required permissions:<br /> <ul> <li>Permission: Crm.RelatieSynchroniseren</li></ul>
     * @summary Triggered de sync voor opgegegeven relaties, nu nog voornamelijk voor tests en debug doeleinde
     * @param {Array<number>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CcsRelatiePublisherApi
     */
    public ccsRelatiePublisherSyncRelaties(requestBody?: Array<number>, options?: any) {
        return CcsRelatiePublisherApiFp(this.configuration).ccsRelatiePublisherSyncRelaties(requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EnvironmentApiApi - axios parameter creator
 * @export
 */
export const EnvironmentApiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Geeft de gegevens van de huidige omgeving terug; inclusief welke features er aan/uit staan
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        environmentGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/environment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EnvironmentApiApi - functional programming interface
 * @export
 */
export const EnvironmentApiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EnvironmentApiApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Geeft de gegevens van de huidige omgeving terug; inclusief welke features er aan/uit staan
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async environmentGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManagementAPIOverigEnvironmentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.environmentGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EnvironmentApiApi - factory interface
 * @export
 */
export const EnvironmentApiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EnvironmentApiApiFp(configuration)
    return {
        /**
         * 
         * @summary Geeft de gegevens van de huidige omgeving terug; inclusief welke features er aan/uit staan
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        environmentGet(options?: any): AxiosPromise<ManagementAPIOverigEnvironmentDto> {
            return localVarFp.environmentGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EnvironmentApiApi - interface
 * @export
 * @interface EnvironmentApiApi
 */
export interface EnvironmentApiApiInterface {
    /**
     * 
     * @summary Geeft de gegevens van de huidige omgeving terug; inclusief welke features er aan/uit staan
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvironmentApiApiInterface
     */
    environmentGet(options?: any): AxiosPromise<ManagementAPIOverigEnvironmentDto>;

}

/**
 * EnvironmentApiApi - object-oriented interface
 * @export
 * @class EnvironmentApiApi
 * @extends {BaseAPI}
 */
export class EnvironmentApiApi extends BaseAPI implements EnvironmentApiApiInterface {
    /**
     * 
     * @summary Geeft de gegevens van de huidige omgeving terug; inclusief welke features er aan/uit staan
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvironmentApiApi
     */
    public environmentGet(options?: any) {
        return EnvironmentApiApiFp(this.configuration).environmentGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * KvkApi - axios parameter creator
 * @export
 */
export const KvkApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kvkGetBedrijf: async (search?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/kvk/bedrijven`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} kvknummer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kvkGetBedrijfsProfiel: async (kvknummer: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'kvknummer' is not null or undefined
            assertParamExists('kvkGetBedrijfsProfiel', 'kvknummer', kvknummer)
            const localVarPath = `/api/v1/kvk/profielen/{kvknummer}`
                .replace(`{${"kvknummer"}}`, encodeURIComponent(String(kvknummer)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KvkApi - functional programming interface
 * @export
 */
export const KvkApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KvkApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kvkGetBedrijf(search?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CollectionResultOfSUREbusinessManagementKvkKvkBedrijfDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kvkGetBedrijf(search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} kvknummer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kvkGetBedrijfsProfiel(kvknummer: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result1OfSUREbusinessManagementKvkKvkBedrijfsProfielDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kvkGetBedrijfsProfiel(kvknummer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KvkApi - factory interface
 * @export
 */
export const KvkApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KvkApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kvkGetBedrijf(search?: string, options?: any): AxiosPromise<CollectionResultOfSUREbusinessManagementKvkKvkBedrijfDto> {
            return localVarFp.kvkGetBedrijf(search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} kvknummer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kvkGetBedrijfsProfiel(kvknummer: string, options?: any): AxiosPromise<Result1OfSUREbusinessManagementKvkKvkBedrijfsProfielDto> {
            return localVarFp.kvkGetBedrijfsProfiel(kvknummer, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KvkApi - interface
 * @export
 * @interface KvkApi
 */
export interface KvkApiInterface {
    /**
     * 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KvkApiInterface
     */
    kvkGetBedrijf(search?: string, options?: any): AxiosPromise<CollectionResultOfSUREbusinessManagementKvkKvkBedrijfDto>;

    /**
     * 
     * @param {string} kvknummer 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KvkApiInterface
     */
    kvkGetBedrijfsProfiel(kvknummer: string, options?: any): AxiosPromise<Result1OfSUREbusinessManagementKvkKvkBedrijfsProfielDto>;

}

/**
 * KvkApi - object-oriented interface
 * @export
 * @class KvkApi
 * @extends {BaseAPI}
 */
export class KvkApi extends BaseAPI implements KvkApiInterface {
    /**
     * 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KvkApi
     */
    public kvkGetBedrijf(search?: string, options?: any) {
        return KvkApiFp(this.configuration).kvkGetBedrijf(search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} kvknummer 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KvkApi
     */
    public kvkGetBedrijfsProfiel(kvknummer: string, options?: any) {
        return KvkApiFp(this.configuration).kvkGetBedrijfsProfiel(kvknummer, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LandenApiApi - axios parameter creator
 * @export
 */
export const LandenApiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Haal alle landen op
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        landenGetLanden: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/landen`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LandenApiApi - functional programming interface
 * @export
 */
export const LandenApiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LandenApiApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Haal alle landen op
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async landenGetLanden(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CollectionResultOfSUREbusinessManagementLandenGetLandenLandDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.landenGetLanden(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LandenApiApi - factory interface
 * @export
 */
export const LandenApiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LandenApiApiFp(configuration)
    return {
        /**
         * 
         * @summary Haal alle landen op
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        landenGetLanden(options?: any): AxiosPromise<CollectionResultOfSUREbusinessManagementLandenGetLandenLandDto> {
            return localVarFp.landenGetLanden(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LandenApiApi - interface
 * @export
 * @interface LandenApiApi
 */
export interface LandenApiApiInterface {
    /**
     * 
     * @summary Haal alle landen op
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LandenApiApiInterface
     */
    landenGetLanden(options?: any): AxiosPromise<CollectionResultOfSUREbusinessManagementLandenGetLandenLandDto>;

}

/**
 * LandenApiApi - object-oriented interface
 * @export
 * @class LandenApiApi
 * @extends {BaseAPI}
 */
export class LandenApiApi extends BaseAPI implements LandenApiApiInterface {
    /**
     * 
     * @summary Haal alle landen op
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LandenApiApi
     */
    public landenGetLanden(options?: any) {
        return LandenApiApiFp(this.configuration).landenGetLanden(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OfferteCompareApi - axios parameter creator
 * @export
 */
export const OfferteCompareApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Inregeling.InregelingInzien</li></ul>
         * @param {string} offertenummer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerteCompareGetByOffertenummer: async (offertenummer: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'offertenummer' is not null or undefined
            assertParamExists('offerteCompareGetByOffertenummer', 'offertenummer', offertenummer)
            const localVarPath = `/api/v1/offerte-compare/offerte/{offertenummer}`
                .replace(`{${"offertenummer"}}`, encodeURIComponent(String(offertenummer)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OfferteCompareApi - functional programming interface
 * @export
 */
export const OfferteCompareApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OfferteCompareApiAxiosParamCreator(configuration)
    return {
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Inregeling.InregelingInzien</li></ul>
         * @param {string} offertenummer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offerteCompareGetByOffertenummer(offertenummer: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result1OfSUREbusinessManagementOfferteOfferteCompareDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offerteCompareGetByOffertenummer(offertenummer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OfferteCompareApi - factory interface
 * @export
 */
export const OfferteCompareApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OfferteCompareApiFp(configuration)
    return {
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Inregeling.InregelingInzien</li></ul>
         * @param {string} offertenummer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerteCompareGetByOffertenummer(offertenummer: string, options?: any): AxiosPromise<Result1OfSUREbusinessManagementOfferteOfferteCompareDto> {
            return localVarFp.offerteCompareGetByOffertenummer(offertenummer, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OfferteCompareApi - interface
 * @export
 * @interface OfferteCompareApi
 */
export interface OfferteCompareApiInterface {
    /**
     *  <br /> Required permissions:<br /> <ul> <li>Permission: Inregeling.InregelingInzien</li></ul>
     * @param {string} offertenummer 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferteCompareApiInterface
     */
    offerteCompareGetByOffertenummer(offertenummer: string, options?: any): AxiosPromise<Result1OfSUREbusinessManagementOfferteOfferteCompareDto>;

}

/**
 * OfferteCompareApi - object-oriented interface
 * @export
 * @class OfferteCompareApi
 * @extends {BaseAPI}
 */
export class OfferteCompareApi extends BaseAPI implements OfferteCompareApiInterface {
    /**
     *  <br /> Required permissions:<br /> <ul> <li>Permission: Inregeling.InregelingInzien</li></ul>
     * @param {string} offertenummer 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferteCompareApi
     */
    public offerteCompareGetByOffertenummer(offertenummer: string, options?: any) {
        return OfferteCompareApiFp(this.configuration).offerteCompareGetByOffertenummer(offertenummer, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OnderhoudsApiApi - axios parameter creator
 * @export
 */
export const OnderhoudsApiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Inregeling.InregelingInzien</li></ul>
         * @summary Haalt alle geplande onderhoudsschermen op
         * @param {boolean} [toonInactief] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onderhoudsAll: async (toonInactief?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/onderhoud`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (toonInactief !== undefined) {
                localVarQueryParameter['toonInactief'] = toonInactief;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Inregeling.InregelingInzien</li></ul>
         * @summary Haalt één specifiek onderhoudsscherm op
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onderhoudsGetById: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('onderhoudsGetById', 'id', id)
            const localVarPath = `/api/v1/onderhoud/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Inregeling.InregelingInzien</li></ul>
         * @summary Haalt de logs van één specifiek onderhoudsscherm op
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onderhoudsGetLogsById: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('onderhoudsGetLogsById', 'id', id)
            const localVarPath = `/api/v1/onderhoud/{id}/logs`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Inregeling.InregelingInzien</li></ul>
         * @summary Haalt mogelijke standaardmeldingen op
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onderhoudsGetStandaardmeldingen: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/onderhoud/standaardmeldingen`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Inregeling.InregelingAanpassen</li></ul>
         * @summary Voegt een nieuw onderhoudsvenster toe
         * @param {SUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto} [sUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onderhoudsPost: async (sUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto?: SUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/onderhoud`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Inregeling.InregelingAanpassen</li></ul>
         * @summary Werkt een bestaand onderhoudsscherm bij
         * @param {SUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto} [sUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onderhoudsPut: async (sUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto?: SUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/onderhoud`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OnderhoudsApiApi - functional programming interface
 * @export
 */
export const OnderhoudsApiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OnderhoudsApiApiAxiosParamCreator(configuration)
    return {
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Inregeling.InregelingInzien</li></ul>
         * @summary Haalt alle geplande onderhoudsschermen op
         * @param {boolean} [toonInactief] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async onderhoudsAll(toonInactief?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CollectionResultOfSUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.onderhoudsAll(toonInactief, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Inregeling.InregelingInzien</li></ul>
         * @summary Haalt één specifiek onderhoudsscherm op
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async onderhoudsGetById(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result1OfSUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.onderhoudsGetById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Inregeling.InregelingInzien</li></ul>
         * @summary Haalt de logs van één specifiek onderhoudsscherm op
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async onderhoudsGetLogsById(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CollectionResultOfSUREbusinessManagementOnderhoudDtosOnderhoudsvensterLogDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.onderhoudsGetLogsById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Inregeling.InregelingInzien</li></ul>
         * @summary Haalt mogelijke standaardmeldingen op
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async onderhoudsGetStandaardmeldingen(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CollectionResultOfSUREbusinessManagementOnderhoudDtosOnderhoudsmeldingDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.onderhoudsGetStandaardmeldingen(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Inregeling.InregelingAanpassen</li></ul>
         * @summary Voegt een nieuw onderhoudsvenster toe
         * @param {SUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto} [sUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async onderhoudsPost(sUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto?: SUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.onderhoudsPost(sUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Inregeling.InregelingAanpassen</li></ul>
         * @summary Werkt een bestaand onderhoudsscherm bij
         * @param {SUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto} [sUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async onderhoudsPut(sUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto?: SUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.onderhoudsPut(sUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OnderhoudsApiApi - factory interface
 * @export
 */
export const OnderhoudsApiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OnderhoudsApiApiFp(configuration)
    return {
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Inregeling.InregelingInzien</li></ul>
         * @summary Haalt alle geplande onderhoudsschermen op
         * @param {boolean} [toonInactief] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onderhoudsAll(toonInactief?: boolean, options?: any): AxiosPromise<CollectionResultOfSUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto> {
            return localVarFp.onderhoudsAll(toonInactief, options).then((request) => request(axios, basePath));
        },
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Inregeling.InregelingInzien</li></ul>
         * @summary Haalt één specifiek onderhoudsscherm op
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onderhoudsGetById(id: number, options?: any): AxiosPromise<Result1OfSUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto> {
            return localVarFp.onderhoudsGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Inregeling.InregelingInzien</li></ul>
         * @summary Haalt de logs van één specifiek onderhoudsscherm op
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onderhoudsGetLogsById(id: string, options?: any): AxiosPromise<CollectionResultOfSUREbusinessManagementOnderhoudDtosOnderhoudsvensterLogDto> {
            return localVarFp.onderhoudsGetLogsById(id, options).then((request) => request(axios, basePath));
        },
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Inregeling.InregelingInzien</li></ul>
         * @summary Haalt mogelijke standaardmeldingen op
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onderhoudsGetStandaardmeldingen(options?: any): AxiosPromise<CollectionResultOfSUREbusinessManagementOnderhoudDtosOnderhoudsmeldingDto> {
            return localVarFp.onderhoudsGetStandaardmeldingen(options).then((request) => request(axios, basePath));
        },
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Inregeling.InregelingAanpassen</li></ul>
         * @summary Voegt een nieuw onderhoudsvenster toe
         * @param {SUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto} [sUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onderhoudsPost(sUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto?: SUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto, options?: any): AxiosPromise<void> {
            return localVarFp.onderhoudsPost(sUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto, options).then((request) => request(axios, basePath));
        },
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Inregeling.InregelingAanpassen</li></ul>
         * @summary Werkt een bestaand onderhoudsscherm bij
         * @param {SUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto} [sUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onderhoudsPut(sUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto?: SUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto, options?: any): AxiosPromise<void> {
            return localVarFp.onderhoudsPut(sUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OnderhoudsApiApi - interface
 * @export
 * @interface OnderhoudsApiApi
 */
export interface OnderhoudsApiApiInterface {
    /**
     *  <br /> Required permissions:<br /> <ul> <li>Permission: Inregeling.InregelingInzien</li></ul>
     * @summary Haalt alle geplande onderhoudsschermen op
     * @param {boolean} [toonInactief] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnderhoudsApiApiInterface
     */
    onderhoudsAll(toonInactief?: boolean, options?: any): AxiosPromise<CollectionResultOfSUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto>;

    /**
     *  <br /> Required permissions:<br /> <ul> <li>Permission: Inregeling.InregelingInzien</li></ul>
     * @summary Haalt één specifiek onderhoudsscherm op
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnderhoudsApiApiInterface
     */
    onderhoudsGetById(id: number, options?: any): AxiosPromise<Result1OfSUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto>;

    /**
     *  <br /> Required permissions:<br /> <ul> <li>Permission: Inregeling.InregelingInzien</li></ul>
     * @summary Haalt de logs van één specifiek onderhoudsscherm op
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnderhoudsApiApiInterface
     */
    onderhoudsGetLogsById(id: string, options?: any): AxiosPromise<CollectionResultOfSUREbusinessManagementOnderhoudDtosOnderhoudsvensterLogDto>;

    /**
     *  <br /> Required permissions:<br /> <ul> <li>Permission: Inregeling.InregelingInzien</li></ul>
     * @summary Haalt mogelijke standaardmeldingen op
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnderhoudsApiApiInterface
     */
    onderhoudsGetStandaardmeldingen(options?: any): AxiosPromise<CollectionResultOfSUREbusinessManagementOnderhoudDtosOnderhoudsmeldingDto>;

    /**
     *  <br /> Required permissions:<br /> <ul> <li>Permission: Inregeling.InregelingAanpassen</li></ul>
     * @summary Voegt een nieuw onderhoudsvenster toe
     * @param {SUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto} [sUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnderhoudsApiApiInterface
     */
    onderhoudsPost(sUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto?: SUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto, options?: any): AxiosPromise<void>;

    /**
     *  <br /> Required permissions:<br /> <ul> <li>Permission: Inregeling.InregelingAanpassen</li></ul>
     * @summary Werkt een bestaand onderhoudsscherm bij
     * @param {SUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto} [sUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnderhoudsApiApiInterface
     */
    onderhoudsPut(sUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto?: SUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto, options?: any): AxiosPromise<void>;

}

/**
 * OnderhoudsApiApi - object-oriented interface
 * @export
 * @class OnderhoudsApiApi
 * @extends {BaseAPI}
 */
export class OnderhoudsApiApi extends BaseAPI implements OnderhoudsApiApiInterface {
    /**
     *  <br /> Required permissions:<br /> <ul> <li>Permission: Inregeling.InregelingInzien</li></ul>
     * @summary Haalt alle geplande onderhoudsschermen op
     * @param {boolean} [toonInactief] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnderhoudsApiApi
     */
    public onderhoudsAll(toonInactief?: boolean, options?: any) {
        return OnderhoudsApiApiFp(this.configuration).onderhoudsAll(toonInactief, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <br /> Required permissions:<br /> <ul> <li>Permission: Inregeling.InregelingInzien</li></ul>
     * @summary Haalt één specifiek onderhoudsscherm op
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnderhoudsApiApi
     */
    public onderhoudsGetById(id: number, options?: any) {
        return OnderhoudsApiApiFp(this.configuration).onderhoudsGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <br /> Required permissions:<br /> <ul> <li>Permission: Inregeling.InregelingInzien</li></ul>
     * @summary Haalt de logs van één specifiek onderhoudsscherm op
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnderhoudsApiApi
     */
    public onderhoudsGetLogsById(id: string, options?: any) {
        return OnderhoudsApiApiFp(this.configuration).onderhoudsGetLogsById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <br /> Required permissions:<br /> <ul> <li>Permission: Inregeling.InregelingInzien</li></ul>
     * @summary Haalt mogelijke standaardmeldingen op
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnderhoudsApiApi
     */
    public onderhoudsGetStandaardmeldingen(options?: any) {
        return OnderhoudsApiApiFp(this.configuration).onderhoudsGetStandaardmeldingen(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <br /> Required permissions:<br /> <ul> <li>Permission: Inregeling.InregelingAanpassen</li></ul>
     * @summary Voegt een nieuw onderhoudsvenster toe
     * @param {SUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto} [sUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnderhoudsApiApi
     */
    public onderhoudsPost(sUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto?: SUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto, options?: any) {
        return OnderhoudsApiApiFp(this.configuration).onderhoudsPost(sUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <br /> Required permissions:<br /> <ul> <li>Permission: Inregeling.InregelingAanpassen</li></ul>
     * @summary Werkt een bestaand onderhoudsscherm bij
     * @param {SUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto} [sUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnderhoudsApiApi
     */
    public onderhoudsPut(sUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto?: SUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto, options?: any) {
        return OnderhoudsApiApiFp(this.configuration).onderhoudsPut(sUREbusinessManagementOnderhoudDtosOnderhoudsvensterDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * QueryToolApiApi - axios parameter creator
 * @export
 */
export const QueryToolApiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Tools.QueriesVerwijderen</li></ul>
         * @summary Verwijdert een bestaande query.
         * @param {number} [queryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryToolDeleteQuery: async (queryId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/querytool`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (queryId !== undefined) {
                localVarQueryParameter['queryId'] = queryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Tools.QueriesUitvoeren</li></ul>
         * @summary Voert de opgegeven query uit.
         * @param {SUREbusinessManagementToolsQuerytoolSelectedDatabaseEnum} [databaseEnum] 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryToolExecuteQuery: async (databaseEnum?: SUREbusinessManagementToolsQuerytoolSelectedDatabaseEnum, body?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/querytool/query`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (databaseEnum !== undefined) {
                localVarQueryParameter['databaseEnum'] = databaseEnum;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Tools.QueriesUitvoeren</li></ul>
         * @summary Haalt alle opgeslagen queries op.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryToolGetQueries: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/querytool`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Tools.QueriesUitvoeren</li></ul>
         * @summary Haalt alle tabellen op uit level met hun beschrijving.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryToolGetTabellen: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/querytool/tabellen`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Tools.QueriesUitvoeren</li></ul>
         * @summary Haalt de opgegeven tabel op.
         * @param {string} [tableName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryToolGetTableReference: async (tableName?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/querytool/reference`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (tableName !== undefined) {
                localVarQueryParameter['tableName'] = tableName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Tools.QueriesOpslaan</li></ul>
         * @summary Voegt een nieuwe query toe.
         * @param {number} queryId 
         * @param {ManagementAPIOverigQueryToolApiControllerUpsertQuery} [managementAPIOverigQueryToolApiControllerUpsertQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryToolSaveQuery: async (queryId: number, managementAPIOverigQueryToolApiControllerUpsertQuery?: ManagementAPIOverigQueryToolApiControllerUpsertQuery, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'queryId' is not null or undefined
            assertParamExists('queryToolSaveQuery', 'queryId', queryId)
            const localVarPath = `/api/v1/querytool/{queryId}`
                .replace(`{${"queryId"}}`, encodeURIComponent(String(queryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(managementAPIOverigQueryToolApiControllerUpsertQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * QueryToolApiApi - functional programming interface
 * @export
 */
export const QueryToolApiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = QueryToolApiApiAxiosParamCreator(configuration)
    return {
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Tools.QueriesVerwijderen</li></ul>
         * @summary Verwijdert een bestaande query.
         * @param {number} [queryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queryToolDeleteQuery(queryId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queryToolDeleteQuery(queryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Tools.QueriesUitvoeren</li></ul>
         * @summary Voert de opgegeven query uit.
         * @param {SUREbusinessManagementToolsQuerytoolSelectedDatabaseEnum} [databaseEnum] 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queryToolExecuteQuery(databaseEnum?: SUREbusinessManagementToolsQuerytoolSelectedDatabaseEnum, body?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CollectionResultOfSUREbusinessManagementToolsQuerytoolDtoQueryTable>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queryToolExecuteQuery(databaseEnum, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Tools.QueriesUitvoeren</li></ul>
         * @summary Haalt alle opgeslagen queries op.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queryToolGetQueries(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CollectionResultOfSUREbusinessManagementToolsQuerytoolDtoOdbcQueryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queryToolGetQueries(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Tools.QueriesUitvoeren</li></ul>
         * @summary Haalt alle tabellen op uit level met hun beschrijving.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queryToolGetTabellen(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CollectionResultOfSUREbusinessCCSReadingOdbcGetTabellenTabelDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queryToolGetTabellen(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Tools.QueriesUitvoeren</li></ul>
         * @summary Haalt de opgegeven tabel op.
         * @param {string} [tableName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queryToolGetTableReference(tableName?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result1OfSUREbusinessManagementToolsQuerytoolDtoTableReference>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queryToolGetTableReference(tableName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Tools.QueriesOpslaan</li></ul>
         * @summary Voegt een nieuwe query toe.
         * @param {number} queryId 
         * @param {ManagementAPIOverigQueryToolApiControllerUpsertQuery} [managementAPIOverigQueryToolApiControllerUpsertQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queryToolSaveQuery(queryId: number, managementAPIOverigQueryToolApiControllerUpsertQuery?: ManagementAPIOverigQueryToolApiControllerUpsertQuery, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result1OfSystemInt32>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queryToolSaveQuery(queryId, managementAPIOverigQueryToolApiControllerUpsertQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * QueryToolApiApi - factory interface
 * @export
 */
export const QueryToolApiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = QueryToolApiApiFp(configuration)
    return {
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Tools.QueriesVerwijderen</li></ul>
         * @summary Verwijdert een bestaande query.
         * @param {number} [queryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryToolDeleteQuery(queryId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.queryToolDeleteQuery(queryId, options).then((request) => request(axios, basePath));
        },
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Tools.QueriesUitvoeren</li></ul>
         * @summary Voert de opgegeven query uit.
         * @param {SUREbusinessManagementToolsQuerytoolSelectedDatabaseEnum} [databaseEnum] 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryToolExecuteQuery(databaseEnum?: SUREbusinessManagementToolsQuerytoolSelectedDatabaseEnum, body?: string, options?: any): AxiosPromise<CollectionResultOfSUREbusinessManagementToolsQuerytoolDtoQueryTable> {
            return localVarFp.queryToolExecuteQuery(databaseEnum, body, options).then((request) => request(axios, basePath));
        },
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Tools.QueriesUitvoeren</li></ul>
         * @summary Haalt alle opgeslagen queries op.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryToolGetQueries(options?: any): AxiosPromise<CollectionResultOfSUREbusinessManagementToolsQuerytoolDtoOdbcQueryDto> {
            return localVarFp.queryToolGetQueries(options).then((request) => request(axios, basePath));
        },
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Tools.QueriesUitvoeren</li></ul>
         * @summary Haalt alle tabellen op uit level met hun beschrijving.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryToolGetTabellen(options?: any): AxiosPromise<CollectionResultOfSUREbusinessCCSReadingOdbcGetTabellenTabelDto> {
            return localVarFp.queryToolGetTabellen(options).then((request) => request(axios, basePath));
        },
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Tools.QueriesUitvoeren</li></ul>
         * @summary Haalt de opgegeven tabel op.
         * @param {string} [tableName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryToolGetTableReference(tableName?: string, options?: any): AxiosPromise<Result1OfSUREbusinessManagementToolsQuerytoolDtoTableReference> {
            return localVarFp.queryToolGetTableReference(tableName, options).then((request) => request(axios, basePath));
        },
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Tools.QueriesOpslaan</li></ul>
         * @summary Voegt een nieuwe query toe.
         * @param {number} queryId 
         * @param {ManagementAPIOverigQueryToolApiControllerUpsertQuery} [managementAPIOverigQueryToolApiControllerUpsertQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryToolSaveQuery(queryId: number, managementAPIOverigQueryToolApiControllerUpsertQuery?: ManagementAPIOverigQueryToolApiControllerUpsertQuery, options?: any): AxiosPromise<Result1OfSystemInt32> {
            return localVarFp.queryToolSaveQuery(queryId, managementAPIOverigQueryToolApiControllerUpsertQuery, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * QueryToolApiApi - interface
 * @export
 * @interface QueryToolApiApi
 */
export interface QueryToolApiApiInterface {
    /**
     *  <br /> Required permissions:<br /> <ul> <li>Permission: Tools.QueriesVerwijderen</li></ul>
     * @summary Verwijdert een bestaande query.
     * @param {number} [queryId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueryToolApiApiInterface
     */
    queryToolDeleteQuery(queryId?: number, options?: any): AxiosPromise<void>;

    /**
     *  <br /> Required permissions:<br /> <ul> <li>Permission: Tools.QueriesUitvoeren</li></ul>
     * @summary Voert de opgegeven query uit.
     * @param {SUREbusinessManagementToolsQuerytoolSelectedDatabaseEnum} [databaseEnum] 
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueryToolApiApiInterface
     */
    queryToolExecuteQuery(databaseEnum?: SUREbusinessManagementToolsQuerytoolSelectedDatabaseEnum, body?: string, options?: any): AxiosPromise<CollectionResultOfSUREbusinessManagementToolsQuerytoolDtoQueryTable>;

    /**
     *  <br /> Required permissions:<br /> <ul> <li>Permission: Tools.QueriesUitvoeren</li></ul>
     * @summary Haalt alle opgeslagen queries op.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueryToolApiApiInterface
     */
    queryToolGetQueries(options?: any): AxiosPromise<CollectionResultOfSUREbusinessManagementToolsQuerytoolDtoOdbcQueryDto>;

    /**
     *  <br /> Required permissions:<br /> <ul> <li>Permission: Tools.QueriesUitvoeren</li></ul>
     * @summary Haalt alle tabellen op uit level met hun beschrijving.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueryToolApiApiInterface
     */
    queryToolGetTabellen(options?: any): AxiosPromise<CollectionResultOfSUREbusinessCCSReadingOdbcGetTabellenTabelDto>;

    /**
     *  <br /> Required permissions:<br /> <ul> <li>Permission: Tools.QueriesUitvoeren</li></ul>
     * @summary Haalt de opgegeven tabel op.
     * @param {string} [tableName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueryToolApiApiInterface
     */
    queryToolGetTableReference(tableName?: string, options?: any): AxiosPromise<Result1OfSUREbusinessManagementToolsQuerytoolDtoTableReference>;

    /**
     *  <br /> Required permissions:<br /> <ul> <li>Permission: Tools.QueriesOpslaan</li></ul>
     * @summary Voegt een nieuwe query toe.
     * @param {number} queryId 
     * @param {ManagementAPIOverigQueryToolApiControllerUpsertQuery} [managementAPIOverigQueryToolApiControllerUpsertQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueryToolApiApiInterface
     */
    queryToolSaveQuery(queryId: number, managementAPIOverigQueryToolApiControllerUpsertQuery?: ManagementAPIOverigQueryToolApiControllerUpsertQuery, options?: any): AxiosPromise<Result1OfSystemInt32>;

}

/**
 * QueryToolApiApi - object-oriented interface
 * @export
 * @class QueryToolApiApi
 * @extends {BaseAPI}
 */
export class QueryToolApiApi extends BaseAPI implements QueryToolApiApiInterface {
    /**
     *  <br /> Required permissions:<br /> <ul> <li>Permission: Tools.QueriesVerwijderen</li></ul>
     * @summary Verwijdert een bestaande query.
     * @param {number} [queryId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueryToolApiApi
     */
    public queryToolDeleteQuery(queryId?: number, options?: any) {
        return QueryToolApiApiFp(this.configuration).queryToolDeleteQuery(queryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <br /> Required permissions:<br /> <ul> <li>Permission: Tools.QueriesUitvoeren</li></ul>
     * @summary Voert de opgegeven query uit.
     * @param {SUREbusinessManagementToolsQuerytoolSelectedDatabaseEnum} [databaseEnum] 
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueryToolApiApi
     */
    public queryToolExecuteQuery(databaseEnum?: SUREbusinessManagementToolsQuerytoolSelectedDatabaseEnum, body?: string, options?: any) {
        return QueryToolApiApiFp(this.configuration).queryToolExecuteQuery(databaseEnum, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <br /> Required permissions:<br /> <ul> <li>Permission: Tools.QueriesUitvoeren</li></ul>
     * @summary Haalt alle opgeslagen queries op.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueryToolApiApi
     */
    public queryToolGetQueries(options?: any) {
        return QueryToolApiApiFp(this.configuration).queryToolGetQueries(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <br /> Required permissions:<br /> <ul> <li>Permission: Tools.QueriesUitvoeren</li></ul>
     * @summary Haalt alle tabellen op uit level met hun beschrijving.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueryToolApiApi
     */
    public queryToolGetTabellen(options?: any) {
        return QueryToolApiApiFp(this.configuration).queryToolGetTabellen(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <br /> Required permissions:<br /> <ul> <li>Permission: Tools.QueriesUitvoeren</li></ul>
     * @summary Haalt de opgegeven tabel op.
     * @param {string} [tableName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueryToolApiApi
     */
    public queryToolGetTableReference(tableName?: string, options?: any) {
        return QueryToolApiApiFp(this.configuration).queryToolGetTableReference(tableName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <br /> Required permissions:<br /> <ul> <li>Permission: Tools.QueriesOpslaan</li></ul>
     * @summary Voegt een nieuwe query toe.
     * @param {number} queryId 
     * @param {ManagementAPIOverigQueryToolApiControllerUpsertQuery} [managementAPIOverigQueryToolApiControllerUpsertQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueryToolApiApi
     */
    public queryToolSaveQuery(queryId: number, managementAPIOverigQueryToolApiControllerUpsertQuery?: ManagementAPIOverigQueryToolApiControllerUpsertQuery, options?: any) {
        return QueryToolApiApiFp(this.configuration).queryToolSaveQuery(queryId, managementAPIOverigQueryToolApiControllerUpsertQuery, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportingApiApi - axios parameter creator
 * @export
 */
export const ReportingApiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Rapportage.RapportageInzien</li></ul>
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportingGetReportData: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('reportingGetReportData', 'id', id)
            const localVarPath = `/api/v1/reporting/reports/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Rapportage.RapportageInzien</li></ul>
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportingGetReports: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/reporting/reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportingApiApi - functional programming interface
 * @export
 */
export const ReportingApiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportingApiApiAxiosParamCreator(configuration)
    return {
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Rapportage.RapportageInzien</li></ul>
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportingGetReportData(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result1OfSUREbusinessManagementReportsTableDefintion1OfSystemObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportingGetReportData(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Rapportage.RapportageInzien</li></ul>
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportingGetReports(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CollectionResultOfSUREbusinessManagementReportsGetAvailableReportsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportingGetReports(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportingApiApi - factory interface
 * @export
 */
export const ReportingApiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportingApiApiFp(configuration)
    return {
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Rapportage.RapportageInzien</li></ul>
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportingGetReportData(id: string, options?: any): AxiosPromise<Result1OfSUREbusinessManagementReportsTableDefintion1OfSystemObject> {
            return localVarFp.reportingGetReportData(id, options).then((request) => request(axios, basePath));
        },
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Rapportage.RapportageInzien</li></ul>
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportingGetReports(options?: any): AxiosPromise<CollectionResultOfSUREbusinessManagementReportsGetAvailableReportsDto> {
            return localVarFp.reportingGetReports(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportingApiApi - interface
 * @export
 * @interface ReportingApiApi
 */
export interface ReportingApiApiInterface {
    /**
     *  <br /> Required permissions:<br /> <ul> <li>Permission: Rapportage.RapportageInzien</li></ul>
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApiApiInterface
     */
    reportingGetReportData(id: string, options?: any): AxiosPromise<Result1OfSUREbusinessManagementReportsTableDefintion1OfSystemObject>;

    /**
     *  <br /> Required permissions:<br /> <ul> <li>Permission: Rapportage.RapportageInzien</li></ul>
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApiApiInterface
     */
    reportingGetReports(options?: any): AxiosPromise<CollectionResultOfSUREbusinessManagementReportsGetAvailableReportsDto>;

}

/**
 * ReportingApiApi - object-oriented interface
 * @export
 * @class ReportingApiApi
 * @extends {BaseAPI}
 */
export class ReportingApiApi extends BaseAPI implements ReportingApiApiInterface {
    /**
     *  <br /> Required permissions:<br /> <ul> <li>Permission: Rapportage.RapportageInzien</li></ul>
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApiApi
     */
    public reportingGetReportData(id: string, options?: any) {
        return ReportingApiApiFp(this.configuration).reportingGetReportData(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <br /> Required permissions:<br /> <ul> <li>Permission: Rapportage.RapportageInzien</li></ul>
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApiApi
     */
    public reportingGetReports(options?: any) {
        return ReportingApiApiFp(this.configuration).reportingGetReports(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SearchApi - axios parameter creator
 * @export
 */
export const SearchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Geeft relaties en (niet gearchiveerde) recente activiteiten terug
         * @param {string} [term] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchGet: async (term?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SearchApi - functional programming interface
 * @export
 */
export const SearchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SearchApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Geeft relaties en (niet gearchiveerde) recente activiteiten terug
         * @param {string} [term] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchGet(term?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CollectionResultOfSUREbusinessManagementSearchSearchDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchGet(term, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SearchApi - factory interface
 * @export
 */
export const SearchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SearchApiFp(configuration)
    return {
        /**
         * 
         * @summary Geeft relaties en (niet gearchiveerde) recente activiteiten terug
         * @param {string} [term] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchGet(term?: string, options?: any): AxiosPromise<CollectionResultOfSUREbusinessManagementSearchSearchDto> {
            return localVarFp.searchGet(term, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SearchApi - interface
 * @export
 * @interface SearchApi
 */
export interface SearchApiInterface {
    /**
     * 
     * @summary Geeft relaties en (niet gearchiveerde) recente activiteiten terug
     * @param {string} [term] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApiInterface
     */
    searchGet(term?: string, options?: any): AxiosPromise<CollectionResultOfSUREbusinessManagementSearchSearchDto>;

}

/**
 * SearchApi - object-oriented interface
 * @export
 * @class SearchApi
 * @extends {BaseAPI}
 */
export class SearchApi extends BaseAPI implements SearchApiInterface {
    /**
     * 
     * @summary Geeft relaties en (niet gearchiveerde) recente activiteiten terug
     * @param {string} [term] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public searchGet(term?: string, options?: any) {
        return SearchApiFp(this.configuration).searchGet(term, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SpfApi - axios parameter creator
 * @export
 */
export const SpfApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns the spf status.
         * @param {string} [domain] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spfCheckSpfRecord: async (domain?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/spf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (domain !== undefined) {
                localVarQueryParameter['domain'] = domain;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SpfApi - functional programming interface
 * @export
 */
export const SpfApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SpfApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Returns the spf status.
         * @param {string} [domain] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async spfCheckSpfRecord(domain?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManagementAPIOverigSpfControllerSpfStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.spfCheckSpfRecord(domain, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SpfApi - factory interface
 * @export
 */
export const SpfApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SpfApiFp(configuration)
    return {
        /**
         * 
         * @summary Returns the spf status.
         * @param {string} [domain] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spfCheckSpfRecord(domain?: string, options?: any): AxiosPromise<ManagementAPIOverigSpfControllerSpfStatus> {
            return localVarFp.spfCheckSpfRecord(domain, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SpfApi - interface
 * @export
 * @interface SpfApi
 */
export interface SpfApiInterface {
    /**
     * 
     * @summary Returns the spf status.
     * @param {string} [domain] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpfApiInterface
     */
    spfCheckSpfRecord(domain?: string, options?: any): AxiosPromise<ManagementAPIOverigSpfControllerSpfStatus>;

}

/**
 * SpfApi - object-oriented interface
 * @export
 * @class SpfApi
 * @extends {BaseAPI}
 */
export class SpfApi extends BaseAPI implements SpfApiInterface {
    /**
     * 
     * @summary Returns the spf status.
     * @param {string} [domain] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpfApi
     */
    public spfCheckSpfRecord(domain?: string, options?: any) {
        return SpfApiFp(this.configuration).spfCheckSpfRecord(domain, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ThreadApi - axios parameter creator
 * @export
 */
export const ThreadApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Draait een achtergrondtaak op basis van een url.
         * @param {string} [url] 
         * @param {string} [identifier] 
         * @param {number} [timeOutInSeconds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        threadPost: async (url?: string, identifier?: string, timeOutInSeconds?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/thread`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (url !== undefined) {
                localVarQueryParameter['url'] = url;
            }

            if (identifier !== undefined) {
                localVarQueryParameter['identifier'] = identifier;
            }

            if (timeOutInSeconds !== undefined) {
                localVarQueryParameter['timeOutInSeconds'] = timeOutInSeconds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ThreadApi - functional programming interface
 * @export
 */
export const ThreadApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ThreadApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Draait een achtergrondtaak op basis van een url.
         * @param {string} [url] 
         * @param {string} [identifier] 
         * @param {number} [timeOutInSeconds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async threadPost(url?: string, identifier?: string, timeOutInSeconds?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.threadPost(url, identifier, timeOutInSeconds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ThreadApi - factory interface
 * @export
 */
export const ThreadApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ThreadApiFp(configuration)
    return {
        /**
         * 
         * @summary Draait een achtergrondtaak op basis van een url.
         * @param {string} [url] 
         * @param {string} [identifier] 
         * @param {number} [timeOutInSeconds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        threadPost(url?: string, identifier?: string, timeOutInSeconds?: number, options?: any): AxiosPromise<string> {
            return localVarFp.threadPost(url, identifier, timeOutInSeconds, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ThreadApi - interface
 * @export
 * @interface ThreadApi
 */
export interface ThreadApiInterface {
    /**
     * 
     * @summary Draait een achtergrondtaak op basis van een url.
     * @param {string} [url] 
     * @param {string} [identifier] 
     * @param {number} [timeOutInSeconds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ThreadApiInterface
     */
    threadPost(url?: string, identifier?: string, timeOutInSeconds?: number, options?: any): AxiosPromise<string>;

}

/**
 * ThreadApi - object-oriented interface
 * @export
 * @class ThreadApi
 * @extends {BaseAPI}
 */
export class ThreadApi extends BaseAPI implements ThreadApiInterface {
    /**
     * 
     * @summary Draait een achtergrondtaak op basis van een url.
     * @param {string} [url] 
     * @param {string} [identifier] 
     * @param {number} [timeOutInSeconds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ThreadApi
     */
    public threadPost(url?: string, identifier?: string, timeOutInSeconds?: number, options?: any) {
        return ThreadApiFp(this.configuration).threadPost(url, identifier, timeOutInSeconds, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VoertuigApi - axios parameter creator
 * @export
 */
export const VoertuigApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {VragenVoertuigControleerMeldcodeVoertuigDto} [vragenVoertuigControleerMeldcodeVoertuigDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        voertuigControleerMeldcode: async (vragenVoertuigControleerMeldcodeVoertuigDto?: VragenVoertuigControleerMeldcodeVoertuigDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/voertuig/meldcode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vragenVoertuigControleerMeldcodeVoertuigDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Inregeling.InregelingInzien</li></ul>
         * @summary Geeft de debuginfo tbv voertuigdata koppeling tool.
         * @param {ManagementAPIOverigVoertuigControllerDebugInfoZoekopdracht} [managementAPIOverigVoertuigControllerDebugInfoZoekopdracht] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        voertuigGetDebugInfo: async (managementAPIOverigVoertuigControllerDebugInfoZoekopdracht?: ManagementAPIOverigVoertuigControllerDebugInfoZoekopdracht, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/voertuig/debuginfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(managementAPIOverigVoertuigControllerDebugInfoZoekopdracht, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [kenteken] 
         * @param {Array<string>} [voertuigsoort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        voertuigGetVoertuig: async (kenteken?: string, voertuigsoort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/voertuig`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (kenteken !== undefined) {
                localVarQueryParameter['kenteken'] = kenteken;
            }

            if (voertuigsoort) {
                localVarQueryParameter['voertuigsoort'] = voertuigsoort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VoertuigApi - functional programming interface
 * @export
 */
export const VoertuigApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VoertuigApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {VragenVoertuigControleerMeldcodeVoertuigDto} [vragenVoertuigControleerMeldcodeVoertuigDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async voertuigControleerMeldcode(vragenVoertuigControleerMeldcodeVoertuigDto?: VragenVoertuigControleerMeldcodeVoertuigDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result1OfSystemBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.voertuigControleerMeldcode(vragenVoertuigControleerMeldcodeVoertuigDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Inregeling.InregelingInzien</li></ul>
         * @summary Geeft de debuginfo tbv voertuigdata koppeling tool.
         * @param {ManagementAPIOverigVoertuigControllerDebugInfoZoekopdracht} [managementAPIOverigVoertuigControllerDebugInfoZoekopdracht] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async voertuigGetDebugInfo(managementAPIOverigVoertuigControllerDebugInfoZoekopdracht?: ManagementAPIOverigVoertuigControllerDebugInfoZoekopdracht, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result1OfManagementAPIOverigVoertuigControllerDebugInfoDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.voertuigGetDebugInfo(managementAPIOverigVoertuigControllerDebugInfoZoekopdracht, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [kenteken] 
         * @param {Array<string>} [voertuigsoort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async voertuigGetVoertuig(kenteken?: string, voertuigsoort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CollectionResultOfVragenVoertuigGetVoertuigDataByKentekenVoertuigDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.voertuigGetVoertuig(kenteken, voertuigsoort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VoertuigApi - factory interface
 * @export
 */
export const VoertuigApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VoertuigApiFp(configuration)
    return {
        /**
         * 
         * @param {VragenVoertuigControleerMeldcodeVoertuigDto} [vragenVoertuigControleerMeldcodeVoertuigDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        voertuigControleerMeldcode(vragenVoertuigControleerMeldcodeVoertuigDto?: VragenVoertuigControleerMeldcodeVoertuigDto, options?: any): AxiosPromise<Result1OfSystemBoolean> {
            return localVarFp.voertuigControleerMeldcode(vragenVoertuigControleerMeldcodeVoertuigDto, options).then((request) => request(axios, basePath));
        },
        /**
         *  <br /> Required permissions:<br /> <ul> <li>Permission: Inregeling.InregelingInzien</li></ul>
         * @summary Geeft de debuginfo tbv voertuigdata koppeling tool.
         * @param {ManagementAPIOverigVoertuigControllerDebugInfoZoekopdracht} [managementAPIOverigVoertuigControllerDebugInfoZoekopdracht] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        voertuigGetDebugInfo(managementAPIOverigVoertuigControllerDebugInfoZoekopdracht?: ManagementAPIOverigVoertuigControllerDebugInfoZoekopdracht, options?: any): AxiosPromise<Result1OfManagementAPIOverigVoertuigControllerDebugInfoDto> {
            return localVarFp.voertuigGetDebugInfo(managementAPIOverigVoertuigControllerDebugInfoZoekopdracht, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [kenteken] 
         * @param {Array<string>} [voertuigsoort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        voertuigGetVoertuig(kenteken?: string, voertuigsoort?: Array<string>, options?: any): AxiosPromise<CollectionResultOfVragenVoertuigGetVoertuigDataByKentekenVoertuigDto> {
            return localVarFp.voertuigGetVoertuig(kenteken, voertuigsoort, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VoertuigApi - interface
 * @export
 * @interface VoertuigApi
 */
export interface VoertuigApiInterface {
    /**
     * 
     * @param {VragenVoertuigControleerMeldcodeVoertuigDto} [vragenVoertuigControleerMeldcodeVoertuigDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoertuigApiInterface
     */
    voertuigControleerMeldcode(vragenVoertuigControleerMeldcodeVoertuigDto?: VragenVoertuigControleerMeldcodeVoertuigDto, options?: any): AxiosPromise<Result1OfSystemBoolean>;

    /**
     *  <br /> Required permissions:<br /> <ul> <li>Permission: Inregeling.InregelingInzien</li></ul>
     * @summary Geeft de debuginfo tbv voertuigdata koppeling tool.
     * @param {ManagementAPIOverigVoertuigControllerDebugInfoZoekopdracht} [managementAPIOverigVoertuigControllerDebugInfoZoekopdracht] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoertuigApiInterface
     */
    voertuigGetDebugInfo(managementAPIOverigVoertuigControllerDebugInfoZoekopdracht?: ManagementAPIOverigVoertuigControllerDebugInfoZoekopdracht, options?: any): AxiosPromise<Result1OfManagementAPIOverigVoertuigControllerDebugInfoDto>;

    /**
     * 
     * @param {string} [kenteken] 
     * @param {Array<string>} [voertuigsoort] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoertuigApiInterface
     */
    voertuigGetVoertuig(kenteken?: string, voertuigsoort?: Array<string>, options?: any): AxiosPromise<CollectionResultOfVragenVoertuigGetVoertuigDataByKentekenVoertuigDto>;

}

/**
 * VoertuigApi - object-oriented interface
 * @export
 * @class VoertuigApi
 * @extends {BaseAPI}
 */
export class VoertuigApi extends BaseAPI implements VoertuigApiInterface {
    /**
     * 
     * @param {VragenVoertuigControleerMeldcodeVoertuigDto} [vragenVoertuigControleerMeldcodeVoertuigDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoertuigApi
     */
    public voertuigControleerMeldcode(vragenVoertuigControleerMeldcodeVoertuigDto?: VragenVoertuigControleerMeldcodeVoertuigDto, options?: any) {
        return VoertuigApiFp(this.configuration).voertuigControleerMeldcode(vragenVoertuigControleerMeldcodeVoertuigDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  <br /> Required permissions:<br /> <ul> <li>Permission: Inregeling.InregelingInzien</li></ul>
     * @summary Geeft de debuginfo tbv voertuigdata koppeling tool.
     * @param {ManagementAPIOverigVoertuigControllerDebugInfoZoekopdracht} [managementAPIOverigVoertuigControllerDebugInfoZoekopdracht] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoertuigApi
     */
    public voertuigGetDebugInfo(managementAPIOverigVoertuigControllerDebugInfoZoekopdracht?: ManagementAPIOverigVoertuigControllerDebugInfoZoekopdracht, options?: any) {
        return VoertuigApiFp(this.configuration).voertuigGetDebugInfo(managementAPIOverigVoertuigControllerDebugInfoZoekopdracht, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [kenteken] 
     * @param {Array<string>} [voertuigsoort] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoertuigApi
     */
    public voertuigGetVoertuig(kenteken?: string, voertuigsoort?: Array<string>, options?: any) {
        return VoertuigApiFp(this.configuration).voertuigGetVoertuig(kenteken, voertuigsoort, options).then((request) => request(this.axios, this.basePath));
    }
}


