export const autocomplete = {
  root: ({ props }: Record<string, any>) => ({
    class: [
      'tw-relative tw-min-w-0',
      // Flex
      'tw-inline-flex',
      // Size
      'tw-w-80 tw-max-w-full',
      { 'tw-w-full': props.multiple },
      // Color
      { 'tw-text-grey-800': !props.disabled },
      { 'tw-text-grey-400': props.disabled },
      // States
      { 'tw-select-none tw-pointer-events-none tw-cursor-default': props.disabled }
    ]
  }),
  pcInput: ({ props }: Record<string, any>) => ({
    root: {
      class: [
        'tw-min-w-0 group-[&>*:not(:first-child)]/input-group:tw-rounded-l-none group-[&>*:not(:last-child)]/input-group:tw-rounded-r-none',
        // Font
        'tw-font-sans tw-leading-none',
        // Position
        'tw-z-0',
        'tw-grow tw-min-w-0',
        // Shape
        'tw-appearance-none tw-outline-none tw-rounded-lg',
        { 'tw-rounded-tr-none tw-rounded-br-none': props.dropdown },
        { 'tw-shadow-none tw-rounded-none': props.multiple },
        // Size
        { 'tw-min-h-11': !props.multiple },
        { 'tw-w-full': props.multiple },
        // Spacing
        'tw-m-0',
        {
          'tw-pr-0': props.dropdown,
          'tw-py-2 tw-px-4': !props.multiple,
          'tw-p-0': props.multiple
        },
        // Colors
        'focus:tw-filter-none hover:tw-filter-none tw-text-inherit',
        {
          'tw-bg-grey-50': props.disabled,
          'tw-border-r-0': props.dropdown,
          'tw-border-warning-700': props.invalid,
          'tw-border tw-border-grey-300 tw-border-solid': !props.multiple,
          'tw-border-0 tw-bg-transparent': props.multiple,
          'tw-bg-white': !props.disabled && !props.multiple,
          'tw-border-grey-300/70': props.disabled && !props.multiple
        },
        // States
        { 'focus:tw-outline-none': !props.multiple },
        // Transition
        'tw-transition-all tw-duration-200'
      ]
    }
  }),
  dropdown: ({ props }: Record<string, any>) => ({
    class: [
      'tw-relative tw-text-sm tw-leading-none',
      'tw-items-center tw-inline-flex tw-text-center tw-align-bottom',
      'tw-rounded-r-lg',
      'tw-px-4 tw-py-1.5',
      'tw-text-inherit',
      'tw-border tw-border-grey-300 tw-border-l-0 tw-border-solid',
      'tw-transition-all tw-duration-200',
      'tw-outline-none tw-outline-0',
      { 'tw-bg-white': !props.disabled },
      { 'tw-bg-grey-50 tw-border-grey-300/70': props.disabled },
      { 'tw-border-warning-700': props.invalid }
    ]
  }),
  loader: ({ props }: Record<string, any>) => ({
    class: [
      // disable the built-in loader when querying. Due to a 'feature', it's very difficult to reset the loading state when cancelling a query.
      // https://github.com/primefaces/primevue/issues/5601#issuecomment-2076067073
      // Instead, it's better to control the loading state manually in the search function, in which case props.loading will be true and the loader will be shown.
      { 'tw-hidden': !props.loading },
      'tw-text-sm tw-leading-none tw-text-primary tw-bg-transparent',
      'tw-absolute tw-top-[50%] [translate:0_-50%] tw-animate-spin',
      { 'tw-right-4': !props.dropdown },
      { 'tw-right-12': props.dropdown }
    ]
  }),
  overlay: {
    class: [
      // Shape
      'tw-box-content',
      'tw-pr-[45px]',
      'tw-border tw-border-solid tw-border-grey-200',
      'tw-rounded-lg',
      'tw-shadow',
      'tw-overflow-auto',
      // Color
      'tw-bg-white'
    ]
  },
  list: {
    class: 'tw-py-2 tw-mr-[-45px] tw-list-none tw-m-0'
  },
  option: ({ context }: Record<string, any>) => ({
    class: [
      // Font
      'tw-leading-none',
      { 'tw-font-normal': !context.selected, 'tw-font-bold': context.selected },
      // Position
      'tw-relative',
      // Shape
      'tw-border-0',
      'tw-rounded-none',
      // Spacing
      'tw-m-0',
      'tw-py-2 tw-px-4',
      // Color
      {
        'tw-bg-primary-200': context.focused && !context.selected
      },
      {
        'tw-bg-primary tw-text-white': context.focused && context.selected
      },
      { 'tw-bg-transparent': !context.focused && context.selected },
      // States
      'hover:tw-bg-primary hover:tw-text-white',
      // Misc
      'tw-cursor-pointer',
      'tw-overflow-hidden',
      'tw-whitespace-nowrap',
      'tw-group/option'
    ]
  }),
  optionGroup: {
    class: ['tw-font-bold', 'tw-m-0', 'tw-py-2 tw-px-4', 'tw-bg-white', 'tw-cursor-auto']
  },
  emptyMessage: {
    class: ['tw-leading-none', 'tw-py-2 tw-px-4', 'tw-bg-transparent']
  },
  transition: {
    enterFromClass: 'tw-opacity-0 tw-scale-y-[0.8]',
    enterActiveClass: 'tw-transition-[transform,opacity] tw-duration-[120ms] tw-ease-[cubic-bezier(0,0,0.2,1)]',
    leaveActiveClass: 'tw-transition-opacity tw-duration-100 tw-ease-linear',
    leaveToClass: 'tw-opacity-0'
  },

  // styling for Autocomplete in multiple select mode
  inputMultiple: ({ props }: Record<string, any>) => ({
    class: [
      // Font
      'tw-font-sans tw-leading-none',
      // Flex
      'tw-flex tw-items-center tw-flex-wrap',
      'tw-gap-1',
      // Spacing
      'tw-m-0 tw-list-none',
      'tw-px-4 tw-py-1',
      // Size
      'tw-w-full',
      // Shape
      'tw-appearance-none tw-rounded-l-md',
      // Color
      'tw-text-grey-800',
      {
        'tw-bg-white': !props.disabled,
        'tw-bg-grey-50': props.disabled
      },
      // Transition
      'tw-transition tw-duration-200 tw-ease-in-out',
      // Misc
      'tw-cursor-text tw-overflow-hidden',
      'tw-outline-none',
      'tw-border-y tw-border-l tw-border-solid tw-border-grey-300',
      'tw-w-80 tw-max-w-full'
    ]
  }),
  chipItem: {
    class: ['tw-max-w-full']
  },
  input: ({ props }: Record<string, any>) => ({
    class: [
      // Font
      'tw-font-sans tw-leading-none',
      // Position
      'tw-z-0',
      // Shape
      'tw-appearance-none tw-outline-none tw-rounded-lg',
      { 'tw-rounded-tr-none tw-rounded-br-none': props.dropdown },
      { 'tw-shadow-none tw-rounded-none': props.multiple },
      // Size
      { 'tw-min-h-11': !props.multiple },
      { 'tw-w-full': props.multiple },
      // Spacing
      'tw-m-0',
      {
        'tw-pr-0': props.dropdown,
        'tw-py-2 tw-px-4': !props.multiple,
        'tw-p-0': props.multiple
      },
      // Colors
      'focus:tw-filter-none hover:tw-filter-none tw-text-inherit',
      {
        'tw-bg-grey-50': props.disabled,
        'tw-border-r-0': props.dropdown,
        'tw-border-warning-700': props.invalid,
        'tw-border tw-border-grey-300 tw-border-solid': !props.multiple,
        'tw-border-0 tw-bg-transparent': props.multiple,
        'tw-bg-white': !props.disabled && !props.multiple,
        'tw-border-grey-300/70': props.disabled && !props.multiple
      },
      // States
      { 'focus:tw-outline-none': !props.multiple },
      // Transition
      'tw-transition-all tw-duration-200'
    ]
  }),
  inputChip: {
    class: ['tw-py-1.5 tw-px-0', 'tw-inline-flex tw-flex-auto']
  },
  pcChip: ({ parent }: Record<string, any>) => ({
    root: {
      class: [
        'tw-inline-flex tw-items-center',
        'tw-py-1.5 tw-px-3',
        'tw-text-sm',
        'tw-rounded',
        'tw-border',
        'tw-text-primary-900',
        'tw-border-grey-200',
        'tw-max-w-full',
        {
          'tw-bg-white': !parent.props.disabled,
          'tw-bg-grey-50': parent.props.disabled
        }
      ]
    },
    label: {
      class: ['tw-max-w-full', 'tw-overflow-x-hidden', 'tw-text-ellipsis', 'tw-leading-5', 'tw-whitespace-nowrap']
    }
  })
};
