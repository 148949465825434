import { HeaderItem, LinkItem, MenuItem } from './types';

export interface Tellingen {
  aantalSchades: number;
  aantalAanvragen: number;
  aantalAanvragenmutaties: number;
  aantalOoditchanges: number;
  aantalPremievastellingen: number;
  aantalMaatwerk: number;
  spfMeldingen: number;
}
interface EnvironmentSettings {
  isMaatschappij: boolean;
  isInterneGebruiker: boolean;
  isFinancieel: boolean;
  isInstellingsRechten: boolean;
  isActiveAdviseur: boolean;
  heeftAdviseurbeheerRechten: boolean;
  heeftBeheerboxRechten: boolean;
  heeftRoyerenPermissie: boolean;
  heeftMutatieStartenPermissie: boolean;
  inspectieAanvragenV2Toggle: boolean;
  tellingen?: Tellingen;
}

function createHeaderItem(title: string): HeaderItem {
  return { itemtype: 'header', title };
}

const defaultTellingen: Tellingen = {
  aantalSchades: 0,
  aantalAanvragen: 0,
  aantalAanvragenmutaties: 0,
  aantalOoditchanges: 0,
  aantalPremievastellingen: 0,
  aantalMaatwerk: 0,
  spfMeldingen: 0
};

export function createMenuItems(settings: EnvironmentSettings): MenuItem[] {
  const {
    isMaatschappij,
    isInterneGebruiker,
    isFinancieel,
    isInstellingsRechten,
    isActiveAdviseur,
    heeftAdviseurbeheerRechten,
    heeftRoyerenPermissie,
    heeftMutatieStartenPermissie,
    tellingen = defaultTellingen,
    heeftBeheerboxRechten,
    inspectieAanvragenV2Toggle
  } = settings;
  const items: MenuItem[] = [];

  // Voeg standaard items toe
  items.push({
    itemtype: 'link',
    title: 'Dashboard',
    url: '/content/',
    iconclass: 'fa-solid fa-house-window'
  });

  if (isActiveAdviseur || isInterneGebruiker || isMaatschappij) {
    items.push({
      itemtype: 'link',
      title: 'Risicoscan',
      url: '/net/riskinventarisation/',
      iconclass: 'fas fa-wrench'
    });
  }
  if (isActiveAdviseur || isInterneGebruiker || isMaatschappij) {
    items.push({
      itemtype: 'link',
      title: 'Offreren / Aanvragen',
      url: '/content/boaz/',
      iconclass: 'fas fa-calculator'
    });
  }

  if (heeftRoyerenPermissie || heeftMutatieStartenPermissie) {
    const title =
      heeftMutatieStartenPermissie && heeftRoyerenPermissie
        ? 'Muteren / Royeren'
        : heeftMutatieStartenPermissie && !heeftRoyerenPermissie
          ? 'Muteren'
          : 'Royeren';

    items.push({
      itemtype: 'link',
      title,
      url: '/net/relation/mutate/',
      iconclass: 'fas fa-file-signature'
    });
  }

  items.push({
    itemtype: 'link',
    title: 'Relatiegegevens',
    url: '/net/relation/index/',
    iconclass: 'fas fa-network-wired'
  });

  items.push({
    itemtype: 'link',
    title: 'Schades',
    url: '/content/schade/',
    iconclass: 'fas fa-car-burst',
    showbadge: tellingen.aantalSchades > 0,
    badgecontent: tellingen.aantalSchades
  });

  items.push(createHeaderItem('Aanvragen'));

  if (isActiveAdviseur || isInterneGebruiker) {
    items.push({
      itemtype: 'link',
      title: 'Aanvragen',
      url: '/content/boaz/aanvraag/?typeaanvraag=aanvraag',
      iconclass: 'far fa-calculator',
      showbadge: tellingen.aantalAanvragen > 0,
      badgecontent: tellingen.aantalAanvragen
    });

    items.push({
      itemtype: 'link',
      title: 'Mutaties',
      url: '/content/boaz/aanvraag/?typeaanvraag=mutatie',
      iconclass: 'far fa-pen-to-square',
      showbadge: tellingen.aantalAanvragenmutaties > 0,
      badgecontent: tellingen.aantalAanvragenmutaties
    });

    if (isInterneGebruiker) {
      items.push({
        itemtype: 'link',
        title: 'Inspectierapporten',
        url: '/net/oodit/RiskPlansOverview',
        iconclass: 'fas fa-clipboard-list-check',
        showbadge: tellingen.aantalOoditchanges > 0,
        badgecontent: tellingen.aantalOoditchanges
      });
    }

    if (inspectieAanvragenV2Toggle) {
      items.push({
        itemtype: 'link',
        title: 'Inspectierapporten (nieuw)',
        url: '/app/aanvraag/inspectie/',
        iconclass: 'fas fa-clipboard-list-check',
        target: '_top'
      });
    }

    items.push({
      itemtype: 'link',
      title: 'Premievaststelling',
      url: '/app/offerte/premievaststelling',
      iconclass: 'fas fa-percent',
      showbadge: tellingen.aantalPremievastellingen > 0,
      badgecontent: tellingen.aantalPremievastellingen,
      target: '_top'
    });
  }

  if (!isMaatschappij || isInterneGebruiker) {
    items.push({
      itemtype: 'link',
      title: 'Prolongatie',
      url: '/net/report/prolongatieoverzicht',
      iconclass: 'fas fa-arrows-rotate'
    });
  }

  if (isInterneGebruiker) {
    items.push({
      itemtype: 'link',
      title: 'Werkvoorraad',
      url: '/net/rapportageintern/werkvoorraad',
      iconclass: 'fas fa-box'
    });

    items.push({
      itemtype: 'link',
      title: 'PTS overzicht',
      url: '/app/beurs/pts/dossiers',
      iconclass: 'fas fa-bullseye-arrow',
      target: '_top'
    });
  }

  if (isActiveAdviseur || isMaatschappij || isInterneGebruiker) {
    items.push({
      itemtype: 'link',
      title: 'Maatwerk',
      url: '/content/boaz/maatwerk/',
      iconclass: 'fas fa-ruler',
      showbadge: tellingen.aantalMaatwerk > 0,
      badgecontent: tellingen.aantalMaatwerk
    });
  }

  if (isActiveAdviseur || isInterneGebruiker) {
    items.push({
      itemtype: 'link',
      title: 'Alerts',
      url: '/app/crm/alerts/polisalerts',
      iconclass: 'fas fa-bell-on',
      target: '_top'
    });
  }

  if (isInterneGebruiker) {
    if (heeftBeheerboxRechten || heeftAdviseurbeheerRechten) {
      items.push(createHeaderItem('Beheer'));
    }

    if (heeftBeheerboxRechten) {
      items.push({
        itemtype: 'link',
        title: 'BeheerBox',
        url: '/app/beheerbox/',
        iconclass: 'far fa-screwdriver-wrench',
        target: '_top'
      });
    }

    if (heeftAdviseurbeheerRechten) {
      items.push({
        itemtype: 'link',
        title: 'Adviseurbeheer',
        url: '/content/beheer/intern/tussenpersonen/',
        iconclass: 'far fa-handshake-simple'
      });
    }

    items.push({
      itemtype: 'link',
      title: 'Rapportage',
      url: '/content/rapportage/intern/',
      iconclass: 'far fa-chart-pie'
    });

    items.push({
      itemtype: 'link',
      title: 'SB Landkaart',
      url: '/net/rapportageintern/SBLandkaart/',
      target: '_blank',
      iconclass: 'far fa-map-location-dot'
    });
  }

  if (isActiveAdviseur || isMaatschappij || isInterneGebruiker) {
    items.push(createHeaderItem('Overzichten'));
  }

  items.push({
    itemtype: 'link',
    title: 'Contact',
    url: '/net/home/about/',
    iconclass: 'far fa-phone-volume'
  });

  const instellingenItem: LinkItem = {
    itemtype: 'link',
    title: 'Instellingen',
    url: '/content/beheer/tussenpersoon/',
    iconclass: 'far fa-cog'
  };
  if (isInstellingsRechten && tellingen.spfMeldingen > 0) {
    instellingenItem.showbadge = true;
    instellingenItem.badgecontent = '1';
  }

  items.push(instellingenItem);

  items.push({
    itemtype: 'link',
    title: 'Overzichten',
    url: '/content/rapportage/',
    iconclass: 'far fa-list'
  });

  if (isFinancieel) {
    items.push({
      itemtype: 'link',
      title: 'Financieel',
      url: '/content/rapportage?fin=1',
      iconclass: 'fas fa-coins'
    });
  }

  return items;
}
