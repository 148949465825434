<script lang="ts" setup>
import { computed } from 'vue';
import { FontAwesomeIcon } from '@shared/plugin';
import { BasicColor } from '@tailwind-preset/colors';

export interface VStatusTagProps {
  color: BasicColor;
  inverted?: boolean;
}

const props = defineProps<VStatusTagProps>();

type ColorClasses = {
  regular: string;
  inverted: string;
};

const colors: Record<BasicColor, ColorClasses> = {
  black: { regular: 'tw-bg-black tw-text-white', inverted: 'tw-text-black' },
  blue: { regular: 'tw-bg-blue tw-text-white', inverted: 'tw-text-blue' },
  grey: { regular: 'tw-bg-grey-200 tw-text-black', inverted: 'tw-text-grey-200' },
  green: { regular: 'tw-bg-green tw-text-black', inverted: 'tw-text-green' },
  orange: { regular: 'tw-bg-orange tw-text-white', inverted: 'tw-text-orange' },
  purple: { regular: 'tw-bg-purple tw-text-white', inverted: 'tw-text-purple' },
  red: { regular: 'tw-bg-red tw-text-white', inverted: 'tw-text-red' },
  teal: { regular: 'tw-bg-teal tw-text-white', inverted: 'tw-text-teal' },
  yellow: { regular: 'tw-bg-yellow tw-text-black', inverted: 'tw-text-yellow' }
} as const;

const colorClasses = computed(() => (props.inverted ? colors[props.color].inverted : colors[props.color].regular));
</script>

<template>
  <span
    class="tw-gap-0.5 tw-rounded-md tw-px-2 tw-py-1 tw-text-sm tw-font-bold"
    :class="{
      'tw-inline-flex tw-items-baseline tw-rounded-s tw-border tw-border-grey-200 tw-bg-white tw-px-2.5 tw-py-[0.438rem]':
        props.inverted,
      [colorClasses]: !props.inverted
    }">
    <FontAwesomeIcon v-if="props.inverted" size="2xs" icon="fa fa-circle" class="tw-mr-1.5" :class="colorClasses" />
    <slot></slot>
  </span>
</template>
