export const floatlabel = {
  root: {
    class: [
      'tw-min-w-0 [&>*:last-child]:tw-max-w-[calc(100%-1.75rem)] [&>*:last-child]:tw-truncate [&>*:last-child]:after:tw-absolute [&>*:last-child]:after:tw-inset-[50%_0_0_0] [&>*:last-child]:after:-tw-z-10 [&>*:last-child]:after:tw-bg-white tw-grow',
      'tw-flex tw-relative',

      // Base Label Appearance
      '[&>*:last-child]:tw-text-grey-400',
      '[&>*:last-child]:tw-absolute',
      '[&>*:last-child]:tw-top-1/2',
      '[&>*:last-child]:-tw-translate-y-1/2',
      '[&>*:last-child]:tw-left-3',
      '[&>*:last-child]:tw-pointer-events-none',
      '[&>*:last-child]:tw-transition-all',
      '[&>*:last-child]:tw-duration-200',
      '[&>*:last-child]:tw-ease',

      // Focus Label Appearance
      '[&>*:last-child]:has-[:focus]:tw-top-0',
      '[&>*:last-child]:has-[:focus]:tw-left-3.5',
      '[&>*:last-child]:has-[:focus]:tw-px-[.1875rem]',
      '[&>*:last-child]:has-[:focus]:tw-text-xs',
      '[&>*:last-child]:has-[:focus]:tw-text-grey-600',

      // Filled Input Label Appearance
      '[&>*:last-child]:has-[.filled]:tw-top-0',
      '[&>*:last-child]:has-[.filled]:tw-left-3.5',
      '[&>*:last-child]:has-[.filled]:tw-px-[.1875rem]',
      '[&>*:last-child]:has-[.filled]:tw-text-xs',
      '[&>*:last-child]:has-[.filled]:tw-text-grey-600',

      // Placeholder Label Appearance
      '[&>*:last-child]:has-[:placeholder-shown]:tw-top-0',
      '[&>*:last-child]:has-[:placeholder-shown]:tw-left-3.5',
      '[&>*:last-child]:has-[:placeholder-shown]:tw-px-[.1875rem]',
      '[&>*:last-child]:has-[:placeholder-shown]:tw-text-xs',
      '[&>*:last-child]:has-[:placeholder-shown]:tw-text-grey-600',

      // Disabled Label Appearance
      '[&>*:last-child]:has-[:disabled]:tw-top-0',
      '[&>*:last-child]:has-[:disabled]:tw-left-3.5',
      '[&>*:last-child]:has-[:disabled]:tw-px-[.1875rem]',
      '[&>*:last-child]:has-[:disabled]:tw-text-xs',
      '[&>*:last-child]:has-[:disabled]:tw-text-grey-600',
      '[&>*:last-child]:has-[:disabled]:after:tw-bg-grey-200',
      '[&>*:last-child]:has-[[data-pc-section="label"][aria-disabled="true"]]:after:tw-bg-grey-200'
    ]
  }
};
