<script lang="ts" setup>
import { computed, useSlots } from 'vue';
import { v4 as uuidv4 } from 'uuid';
import VFormStatus from '../VFormStatus/VFormStatus.vue';

const props = withDefaults(
  defineProps<{
    invertHeader?: boolean;
    marginBottom?: keyof typeof marginBottomClasses;
    noPadding?: boolean;
    title?: string;
    valid?: boolean;
  }>(),
  { hasPadding: true, marginBottom: 2, invertHeader: false, valid: undefined }
);
const contentboxTitleId = `contentbox-title-${uuidv4()}`;
const slot = useSlots();
const hasHeader = computed(() => !!slot.header);

const marginBottomClasses = {
  0: '',
  1: 'tw-mb-1',
  2: 'tw-mb-2',
  3: 'tw-mb-3',
  4: 'tw-mb-4',
  5: 'tw-mb-5',
  6: 'tw-mb-6',
  7: 'tw-mb-7',
  8: 'tw-mb-8'
} as const;
</script>

<template>
  <div
    class="tw-relative tw-block tw-rounded-md tw-border tw-border-solid tw-border-grey-200 tw-bg-white tw-p-0 tw-shadow-none"
    role="region"
    :aria-labelledby="contentboxTitleId">
    <div
      :id="contentboxTitleId"
      class="tw-rounded-t-md tw-border-0 tw-border-b tw-border-solid tw-border-b-grey-200 tw-p-4"
      :class="{ 'tw-bg-grey-100': !invertHeader }"
      v-if="hasHeader || title">
      <slot name="header" :title="props.title" :valid="props.valid">
        <div class="tw-flex tw-justify-between">
          <div class="tw-flex tw-items-center tw-gap-2">
            <VFormStatus v-if="props.valid !== undefined" icon-size="lg" :valid="props.valid" />
            <span
              class="tw-text-sm tw-font-bold"
              :class="{ 'tw-text-primary-900': invertHeader, 'tw-text-primary': !invertHeader }">
              {{ title }}
            </span>
          </div>
          <slot name="header-end"></slot>
        </div>
      </slot>
    </div>
    <div
      :class="[
        marginBottomClasses[props.marginBottom],
        {
          'tw-p-4 lg:tw-p-8': !props.noPadding
        }
      ]">
      <slot></slot>
    </div>
  </div>
</template>
