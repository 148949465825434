import {
  computed,
  ComputedRef,
  inject,
  InjectionKey,
  isRef,
  isProxy,
  toRefs,
  toRaw,
  unref,
  type MaybeRef,
  type Ref
} from 'vue';

export const sleep = (ms: number) =>
  new Promise(r => {
    setTimeout(r, ms);
  });

export function capitalize(string: string) {
  const str = string.toLowerCase();
  return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * clone een item via JSON
 * @deprecated Gebruik de {@link deepClone} tools-lib functie om deep clones van objecten te maken, ipv deze functie. Deze functie kan niet goed overweg met speciale javascript types zoals Dates.
 */
export function clone<T>(item: T): T | undefined {
  if (item === undefined) return undefined;
  return JSON.parse(JSON.stringify(item));
}

type Unref<T> = T extends Ref<infer U> ? U : T;
type DeepUnref<T> = {
  [K in keyof T]: T[K] extends object ? DeepUnref<Unref<T[K]>> : Unref<T[K]>;
};

/**
 * Maak een object vrij van refs met {@link toRawDeep} en maak vervolgens een deep-clone met de in browsers ingebakken {@link structuredClone} functie.
 * @param item
 */
export function deepClone<T>(item: MaybeRef<T>): DeepUnref<T> {
  return structuredClone(toRawDeep(item));
}

export function getFontAwesomeIconFromMimeType(mimeType: string) {
  switch (mimeType) {
    case 'application/msword':
    case 'application/rtf':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return 'file-doc';

    case 'application/pdf':
      return 'file-pdf';

    case 'application/postscript':
      return 'file-eps';

    case 'application/vnd.ms-excel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return 'file-xls';

    case 'application/vnd.ms-outlook':
    case 'message/rfc822':
      return 'envelope';

    case 'image/bmp':
    case 'image/gif':
    case 'image/jpeg':
    case 'image/png':
      return 'image';

    default:
      return 'file';
  }
}

/**
 * pas Vue's {@link https://vuejs.org/api/reactivity-utilities.html#unref unref} en {@link https://vuejs.org/api/reactivity-advanced.html#toraw toRaw} recursief toe, tot een object vrij van reactive vue proxies overblijft.
 * @param item
 */
export function toRawDeep<T>(item: MaybeRef<T>): DeepUnref<T> {
  const recursiveToRaw = (input: any): any => {
    if (Array.isArray(input)) {
      return input.map(x => recursiveToRaw(x));
    }
    if (isRef(input)) {
      return recursiveToRaw(unref(input));
    }
    if (isProxy(input)) {
      return recursiveToRaw(toRaw(input));
    }
    // Constructor check is nodig, want Date, Map, Set objecten etc. zijn ook van type 'object'.
    if (input && typeof input === 'object' && input.constructor === Object) {
      return Object.keys(input).reduce(
        (acc, key) => {
          acc[key] = recursiveToRaw(input[key]);
          return acc;
        },
        {} as Record<any, any>
      );
    }
    return input;
  };

  return recursiveToRaw(item);
}

export function removeDuplicatesInArray(arrayWithDuplicates: any, prop: any) {
  const arrayWithoutDuplicates = arrayWithDuplicates.filter(
    (obj: any, pos: any, arr: any) => arr.map((mapObj: any) => mapObj[prop.toString()]).indexOf(obj[prop]) === pos
  );
  return arrayWithoutDuplicates || [];
}

export function anchorLinks(text: string) {
  return (text || '').replace(/([^\S]|^)(((https?:\/\/)|(www\.))(\S+))/gi, (match, space, url) => {
    let hyperlink = url;
    if (!hyperlink.match('^https?://')) {
      hyperlink = `https://${hyperlink}`;
    }
    return `${space}<a href='${hyperlink}' target='_blank'>${url}</a>`;
  });
}

export function sortArray(arr: any, column: string, ascending = false) {
  arr = [...arr].sort((a, b) => {
    a = a[column];
    b = b[column];

    if (typeof a === 'string' && typeof b === 'string') {
      a = a.toLowerCase();
      b = b.toLowerCase();
    }

    return a > b ? 1 : b > a ? -1 : 0;
  });

  if (!ascending) {
    arr.reverse();
  }
  return arr;
}

export function objectsAreEqual(object1: object, object2: object) {
  return Object.entries(object1).toString() === Object.entries(object2).toString();
}

export function currencyFormatter(value: number | bigint | null | undefined, fractionDigits = 2) {
  if (value === null || value === undefined) return '';

  return new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: fractionDigits,
    minimumFractionDigits: fractionDigits
  }).format(value);
}

export function sanitizeString(str: string) {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export function sanitizeObject(object: any) {
  Object.keys(object).map(k => {
    if (object[k] && typeof object[k] === 'string') {
      object[k] = sanitizeString(object[k]);
    }
    if (object[k] && typeof object[k] === 'object') {
      sanitizeObject(object[k]);
    }
  });
  return object;
}

export function search(object: any, searchTerm: string, field = ''): any {
  if (!object) return;
  const clonedObj = JSON.parse(JSON.stringify(object));

  const cleanObj =
    field && typeof field === 'string' && Object.keys(clonedObj).includes(field)
      ? { field: clonedObj[field] }
      : sanitizeObject(clonedObj);

  const regex = new RegExp(escapeRegExp(searchTerm), 'i');

  return Object.values(cleanObj).find(val => {
    if (typeof val === 'string' && val.match(regex)) {
      return cleanObj;
    }
    if (typeof val === 'object') {
      return search(val, searchTerm, field);
    }
  });
}

function escapeRegExp(input: string) {
  return input.replace(/[-[/\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

export interface GroupedItem<T> {
  key: string;
  items: T[];
}

const getItem = <T>(g: GroupedItem<T>[], key: string) => g.find(x => x.key === key);

export const groupBy = <T, K extends keyof any>(list: T[], getKey: (item: T) => K): GroupedItem<T>[] =>
  list.reduce((previous, currentItem) => {
    const groupKey = getKey(currentItem) as string;
    if (!getItem(previous, groupKey))
      previous.push({
        key: groupKey,
        items: []
      });
    getItem(previous, groupKey)?.items.push(currentItem);
    return previous;
  }, [] as GroupedItem<T>[]);

export function injectStrict<T>(key: InjectionKey<T>, fallback?: T) {
  const resolved = inject(key, fallback);
  if (!resolved) {
    throw new Error(`Could not resolve ${key.description}`);
  }
  return resolved;
}

export function checkIfNumber(value: unknown) {
  if (!Number.isNaN(Number(value))) {
    return Number(value);
  }
  return value;
}

export function scrollToFirstInvalidField(containerId?: string) {
  const scrollContext: HTMLElement | Window = containerId ? document.getElementById(containerId) ?? window : window;
  const firstInvalidField: HTMLElement | null = (
    scrollContext instanceof HTMLElement ? scrollContext : document
  ).querySelector('[data-field--has-error="true"]');

  if (!firstInvalidField) return;

  firstInvalidField.scrollIntoView({
    behavior: 'smooth',
    block: 'center'
  });
}

type ToComputed<T = any> = {
  [K in keyof T]: ComputedRef<T[K]>;
};

export function toComputed<T extends object>(s: T): ToComputed<T> {
  const refs = toRefs(s);
  return Object.keys(s).reduce((prev, curr) => {
    const key = curr as keyof T;
    prev[key] = computed(() => refs[key].value);
    return prev;
  }, {} as ToComputed<T>);
}

export function valOrEmpty(val: string | number | null | undefined) {
  return val ?? '';
}

type FilterFunction<T> = (item: T) => boolean;

export function getCounts<T>(
  items: T[],
  filter: FilterFunction<T>,
  getKey: (item: T) => string | undefined
): Record<string, number> {
  const count: Record<string, number> = {};
  items.filter(filter).forEach(item => {
    const key = getKey(item);
    if (key) {
      count[key] = (count[key] || 0) + 1;
    }
  });
  return count;
}

/** maak een form element aan met de opgegeven URL en submit het vervolgens, om op die manier de gebruiker met een POST request naar een andere url te sturen. */
export function navigateToUrlWithPostRequest(url: string) {
  const form = document.createElement('form');
  form.setAttribute('method', 'POST');
  form.setAttribute('action', url);
  form.style.visibility = 'hidden';
  document.body.appendChild(form);
  form.submit();
}

type EnumType = { [key: string]: string | number };
export const enumToOptions = (enumObj: EnumType) => {
  return Object.entries(enumObj).map(([key, value]) => ({
    label: key,
    value
  }));
};
