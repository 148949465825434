<script lang="ts" setup>
import { FontAwesomeIcon } from '@shared/plugin';
import { computed, defineAsyncComponent, ref } from 'vue';
import type { FontAwesomeIconProps } from '@fortawesome/vue-fontawesome';

import { ModalType } from '../types';
import VSpinnerV2 from '../VSpinnerV2/VSpinnerV2.vue';
import { ButtonType } from './vButtonTypes';

export interface VButtonProps {
  iconOnlyResponsiveness?: boolean;
  type?: ButtonType;
  inverted?: boolean;
  prependIcon?: FontAwesomeIconProps['icon'];
  appendIcon?: FontAwesomeIconProps['icon'];
  loading?: boolean;
  disabled?: boolean;
  confirmDialog?: boolean;
  confirmDialogText?: string;
  confirmDialogButtonText?: string;
  submit?: boolean;
  href?: string;
  hideShadow?: boolean;
  fontSize?: 'xs' | 'sm' | 'lg' | 'xl';
  align?: 'left' | 'center' | 'right';
}

const props = withDefaults(defineProps<VButtonProps>(), {
  type: 'primary',
  hideShadow: false,
  align: 'left'
});
const emit = defineEmits<{ click: [value?: Event] }>();

defineOptions({
  inheritAttrs: false
});

const VModalx = defineAsyncComponent(() => import('../VModal/VModal.vue'));

const modalOpen = ref(false);

const modalType = computed(() => {
  switch (props.type) {
    case 'danger':
      return ModalType.Danger;
    default:
      return ModalType.Success;
  }
});

const spinnerType = computed(() => {
  if (props.type === 'danger') return 'danger';
  if (props.disabled) return 'white';
  return 'primary';
});

function onClick(event: Event) {
  if (!props.confirmDialog) {
    emit('click', event);
    return;
  }
  if (modalOpen.value) {
    emit('click', event);
    modalOpen.value = false;
  } else {
    modalOpen.value = true;
  }
}

const justify = {
  left: 'tw-justify-start',
  center: 'tw-justify-center',
  right: 'tw-justify-end'
};

const buttonClasses = computed(() => {
  let baseClasses = `tw-relative tw-inline-flex tw-items-center ${
    justify[props.align ?? 'left']
  } tw-font-sans tw-box-border tw-h-11 tw-cursor-pointer tw-border tw-rounded tw-px-4 tw-py-5 tw-font-base tw-font-bold tw-leading-normal tw-outline-none tw-transition-[background-color,color] tw-duration-[0.3s] tw-no-underline`;
  const groupClasses =
    'group-[]/v-button-group--attached:tw-grow group-[]/v-button-group--attached:tw-justify-center group-[]/v-multiple-choice-buttons:tw-rounded-none group-[]/v-multiple-choice-buttons:tw-border-l-0 group-[]/v-multiple-choice-buttons:tw-border-t-0 group-[]/v-multiple-choice-buttons:last:tw-rounded-br group-[]/v-button-group--attached:not-first:tw-rounded-l-none group-[]/v-button-group--attached:not-last:tw-rounded-r-none';
  const typeClasses = {
    primary: 'tw-bg-primary-600 tw-text-white hover:tw-bg-primary-700 tw-border-transparent',
    danger: 'tw-bg-red tw-text-white hover:tw-bg-red-700 tw-border-transparent'
  };

  const disabledClasses = 'hover:tw-cursor-no-drop tw-border tw-border-solid tw-border-grey-200 tw-bg-grey-200';
  const invertedClasses = {
    primary:
      'tw-bg-white tw-border tw-border-solid tw-border-grey-100 tw-text-primary-500 hover:tw-bg-grey-100 hover:tw-text-primary-600',
    danger:
      'tw-bg-white tw-border tw-border-solid tw-border-grey-100 tw-text-red hover:tw-text-red-700 hover:tw-bg-grey-100'
  };

  if (!props.hideShadow) {
    baseClasses += ' tw-shadow';
    typeClasses.primary += ' focus:tw-shadow-sm focus:tw-shadow-primary-300';
    typeClasses.danger += ' focus:tw-shadow-sm focus:tw-shadow-red-100';
  }

  const classes = [baseClasses, groupClasses];
  if (props.disabled) {
    classes.push(disabledClasses);
  } else if (props.inverted) {
    classes.push(invertedClasses[props.type]);
  } else {
    classes.push(typeClasses[props.type]);
  }
  return classes.join(' ');
});

const fontSize = computed(() => {
  switch (props.fontSize) {
    case 'xs':
      return ' tw-text-xs';
    case 'sm':
      return ' tw-text-sm';
    case 'lg':
      return ' tw-text-lg';
    case 'xl':
      return ' tw-text-xl';
    default:
      return '';
  }
});
</script>

<template>
  <Component
    :is="href ? 'a' : 'button'"
    :href="href"
    @click.stop="onClick"
    :disabled="disabled"
    :class="[
      buttonClasses,
      fontSize,
      {
        'max-lg:tw-px-[.6875rem]': props.iconOnlyResponsiveness
      }
    ]"
    v-bind="$attrs"
    :type="submit ? 'submit' : 'button'">
    <VSpinnerV2
      :variant="spinnerType"
      class="tw-absolute tw-left-1/2 tw-top-1/2 tw--translate-x-1/2 tw--translate-y-1/2 tw-transform"
      v-if="loading" />

    <FontAwesomeIcon
      v-if="prependIcon"
      :class="{ 'tw-opacity-0': loading }"
      :icon="prependIcon"
      fixed-width
      class="btn-item" />

    <span
      :class="[
        'btn-item',
        {
          'max-lg:tw-hidden': props.iconOnlyResponsiveness,
          'tw-opacity-0': loading
        }
      ]">
      <slot></slot>
    </span>

    <FontAwesomeIcon
      v-if="appendIcon"
      :class="{ 'tw-opacity-0': loading }"
      :icon="appendIcon"
      fixed-width
      class="btn-item" />
  </Component>

  <VModalx
    v-if="confirmDialog"
    :type="modalType"
    :is-active="modalOpen"
    :confirm-button-text="confirmDialogButtonText ?? 'Bevestigen'"
    :loading="loading"
    @confirm-action="(e: any) => onClick(e)"
    @close-modal="modalOpen = false"
    v-bind="$attrs">
    {{ confirmDialogText ?? 'Weet je zeker dat je deze actie wil uitvoeren?' }}
  </VModalx>
</template>

<style lang="scss" scoped>
.btn-item:nth-child(2):not(:empty) {
  padding-left: 0.5em;
}
</style>
