// @ts-nocheck
/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * SUREnet crm-public API
 * OpenAPI spec version: 1
 */
import { useQuery } from '@tanstack/vue-query';
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query';
import { computed, unref } from 'vue';
import type { MaybeRef } from 'vue';
import type { Result1OfCrmAdviseursGetAdviseurByIdAdviseurDto, Result1OfManagementModelsEmpty } from '../model';
import { customInstance } from '../../customInstance';
import type { ErrorType } from '../../customInstance';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 *  <br /> Required permissions:<br /> <ul> <li>Permission: Crm.AdviseurInzien</li></ul>
 * @summary Haal een specifieke adviseur op
 */
export const adviseursGetAdviseurById = (
  id: MaybeRef<number | undefined | null>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  id = unref(id);

  return customInstance<Result1OfCrmAdviseursGetAdviseurByIdAdviseurDto>(
    { url: `/api/v1/adviseurs/${id}`, method: 'GET', signal },
    options
  );
};

export const getAdviseursGetAdviseurByIdQueryKey = (id: MaybeRef<number | undefined | null>) => {
  return ['api', 'v1', 'adviseurs', id] as const;
};

export const getAdviseursGetAdviseurByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof adviseursGetAdviseurById>>,
  TError = ErrorType<void | Result1OfManagementModelsEmpty>
>(
  id: MaybeRef<number | undefined | null>,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof adviseursGetAdviseurById>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getAdviseursGetAdviseurByIdQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof adviseursGetAdviseurById>>> = ({ signal }) =>
    adviseursGetAdviseurById(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: computed(() => !!unref(id)), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof adviseursGetAdviseurById>>,
    TError,
    TData
  >;
};

export type AdviseursGetAdviseurByIdQueryResult = NonNullable<Awaited<ReturnType<typeof adviseursGetAdviseurById>>>;
export type AdviseursGetAdviseurByIdQueryError = ErrorType<void | Result1OfManagementModelsEmpty>;

/**
 * @summary Haal een specifieke adviseur op
 */

export function useAdviseursGetAdviseurById<
  TData = Awaited<ReturnType<typeof adviseursGetAdviseurById>>,
  TError = ErrorType<void | Result1OfManagementModelsEmpty>
>(
  id: MaybeRef<number | undefined | null>,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof adviseursGetAdviseurById>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getAdviseursGetAdviseurByIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}

/**
 *  <br /> Required permissions:<br /> <ul> <li>Permission: Crm.AdviseurInzien</li></ul>
 * @summary Haal de adviseur gegevens van de inglogde gebruiker op
 */
export const adviseursGetHuidigeAdviseur = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<Result1OfCrmAdviseursGetAdviseurByIdAdviseurDto>(
    { url: `/api/v1/adviseurs`, method: 'GET', signal },
    options
  );
};

export const getAdviseursGetHuidigeAdviseurQueryKey = () => {
  return ['api', 'v1', 'adviseurs'] as const;
};

export const getAdviseursGetHuidigeAdviseurQueryOptions = <
  TData = Awaited<ReturnType<typeof adviseursGetHuidigeAdviseur>>,
  TError = ErrorType<void | Result1OfManagementModelsEmpty>
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof adviseursGetHuidigeAdviseur>>, TError, TData>>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getAdviseursGetHuidigeAdviseurQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof adviseursGetHuidigeAdviseur>>> = ({ signal }) =>
    adviseursGetHuidigeAdviseur(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof adviseursGetHuidigeAdviseur>>,
    TError,
    TData
  >;
};

export type AdviseursGetHuidigeAdviseurQueryResult = NonNullable<
  Awaited<ReturnType<typeof adviseursGetHuidigeAdviseur>>
>;
export type AdviseursGetHuidigeAdviseurQueryError = ErrorType<void | Result1OfManagementModelsEmpty>;

/**
 * @summary Haal de adviseur gegevens van de inglogde gebruiker op
 */

export function useAdviseursGetHuidigeAdviseur<
  TData = Awaited<ReturnType<typeof adviseursGetHuidigeAdviseur>>,
  TError = ErrorType<void | Result1OfManagementModelsEmpty>
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof adviseursGetHuidigeAdviseur>>, TError, TData>>;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getAdviseursGetHuidigeAdviseurQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}
