import apiService from '@services/base/apiService';
import { env } from '@configuration-lib';
import {
  BrandingApi,
  EnvironmentApiApi,
  KvkApi,
  ReportingApiApi,
  VoertuigApi,
  AanvragenSettingsApiApi,
  OnderhoudsApiApi,
  QueryToolApiApi,
  LandenApiApi
} from './api';

const baseUrl = env.VUE_APP_MANAGEMENT_BASE_URL;
export const environmentApi = apiService.create(EnvironmentApiApi, baseUrl);
export const kvkApi = apiService.create(KvkApi, baseUrl);
export const voertuigApi = apiService.create(VoertuigApi, baseUrl);
export const brandingApi = apiService.create(BrandingApi, baseUrl);
export const reportApi = apiService.create(ReportingApiApi, baseUrl);
export const aanvragenSettingsApi = apiService.create(AanvragenSettingsApiApi, baseUrl);
export const onderhoudsApi = apiService.create(OnderhoudsApiApi, baseUrl);
export const queryToolApi = apiService.create(QueryToolApiApi, baseUrl);

export const landenApi = apiService.create(LandenApiApi, baseUrl);
